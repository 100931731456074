import React, { useEffect, useState } from 'react'
import { useDispatch, } from 'react-redux'
import { useHistory } from 'react-router';
import { setUser } from '@/store/user';
import { initSkinLayout } from '@/store/comment';
import ajax from '@/services'
import { Input, Message } from '@yisa/webui'
import { setID, aesUtil } from '@/utils'
import { UserOutlined, LockOutlined } from '@yisa/webui/es/Icon';
import './index.scss'

const Login = (props) => {
  const [formData, setFormData] = useState({
    user: "",
    possword: ""
  })
  const dispatch = useDispatch();
  const [style, setStyle] = useState({})

  const login = () => {
    let password = aesUtil.encrypt(formData.possword)
    ajax.login({ user_name: formData.user, password: password }).then(res => {
      if (res?.status === 50000) {
        Message.error(res?.message || '登陆错误')
      } else {
        let _data = res?.data || {}
        dispatch(setUser(_data))
        dispatch(initSkinLayout(_data))
        if (_data && _data.user_info) {
          setID(_data.user_info.id)
          window.user_id = _data.user_info.id
        }
        window.location = window.location.origin
      }
    }).catch(err => {
      console.log(err)
      Message.error(err.message)
    })
  }

  useEffect(() => {
    const handleResize = () => {
      const docEle = document.documentElement
      const docWidth = docEle.clientWidth
      const docHeight = docEle.clientHeight
      if (docHeight > 680) {
        const designRatio = 1920 / 1080
        const realRatio = docWidth / docHeight
        let scaleRate = docWidth / 1920
        if (realRatio > designRatio) {
          scaleRate = docHeight / 1080
        }
        setStyle({ "--scale": scaleRate })
      } else {
        setStyle({
          "--scale-width": "1920px",
          overflow: 'auto'
        })
      }
    }
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <div className="login-main" style={style}>
      <div className="container">
        <div className="login-box">
          <div className="input">
            <UserOutlined />
            <Input
              placeholder="用户名"
              value={formData.user}
              onChange={(v) => {
                setFormData(Object.assign({}, formData, { user: v.target.value }))
              }}
            />
          </div>
          <div className="input">
            <LockOutlined />
            <Input
              placeholder="密码"
              type="password"
              value={formData.possword}
              onChange={(v) => {
                setFormData(Object.assign({}, formData, { possword: v.target.value }))
              }}
            />
          </div>
          <div className="title">忘记密码请联系管理员</div>
          <div className="login-btn" type="primary" onClick={login}>登录</div>
        </div>
      </div>
    </div>
  )
}

export default Login

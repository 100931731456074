import React from 'react'
import { Link } from 'react-router-dom'
import { Icon } from '@yisa/webui/es/Icon'
import './index.scss'

function Home() {

  return <div className="home-page">
    <div className="home-page-content">
      <Link to="/market">
        <div className="nav-item">
          <Icon type="fill-nongmaoshichangguanli" />
          <p>农贸市场管理</p>
        </div>
      </Link>
      <Link to="/gas">
        <div className="nav-item">
          <Icon type="fill-yehuaqichongzhuangguanli" />
          <p>液化气充装管理</p>
        </div>
      </Link>
    </div>
  </div>
}

export default Home

/**
 *  @description  路由配置，菜单导航最多支持三级。
 *
 *  一、 path  路径； name 选中菜单的路径；fixedTheme 固定为某个主题
 *
 *  二、 单独布局的页面放到上面，不然会被其他路由拦截
 *
 * */

import pages from "@/pages";

export default [
  {
    path: "/",
    component: pages.Layout,
    children: [
      {
        path: "/analysis",
        name: '/analysis',
        component: pages.Analysis,
        needPermission: true,
        breadcrumb: [
          {
            text: '违规数据'
          },
        ],
      },
      {
        path: "/cylinderStatistics",
        name: '/cylinderStatistics',
        component: pages.CylinderStatistics,
        needPermission: true,
        breadcrumb: [
          {
            text: '气瓶个数统计'
          },
        ],
      },
      {
        path: "/marketEquipment",
        name: '/marketEquipment',
        component: pages.Equipment.Market,
        needPermission: true,
        breadcrumb: [
          {
            text: '农贸市场管理'
          },
          {
            text: '预警管理'
          },
          {
            text: '设备管理'
          },
        ],
      },
      {
        path: "/gasEquipment",
        name: '/gasEquipment',
        component: pages.Equipment.Gas,
        needPermission: true,
        breadcrumb: [
          {
            text: '液化气充装管理'
          },
          {
            text: '预警管理'
          },
          {
            text: '设备管理'
          },
        ],
      },
      {
        path: "/marketConfiguration",
        name: '/marketConfiguration',
        component: pages.Configuration.Market,
        needPermission: true,
        breadcrumb: [
          {
            text: '农贸市场管理'
          },
          {
            text: '预警管理'
          },
          {
            text: '预警算法配置'
          },
        ],
      },
      {
        path: "/gasConfiguration",
        name: '/gasConfiguration',
        component: pages.Configuration.Gas,
        needPermission: true,
        breadcrumb: [
          {
            text: '液化气充装管理'
          },
          {
            text: '预警管理'
          },
          {
            text: '预警算法配置'
          },
        ],
      },
      {
        path: "/marketWarnJudge",
        name: '/marketWarnJudge',
        component: pages.WarnJudge.Market,
        needPermission: true,
        breadcrumb: [
          {
            text: '农贸市场管理'
          },
          {
            text: '预警管理'
          },
          {
            text: '违规预警详情'
          },
        ],
      },
      {
        path: "/gasWarnJudge",
        name: '/gasWarnJudge',
        component: pages.WarnJudge.Gas,
        needPermission: true,
        breadcrumb: [
          {
            text: '液化气充装管理'
          },
          {
            text: '预警管理'
          },
          {
            text: '违规预警详情'
          },
        ],
      },
      {
        path: "/person_archives",
        name: '/person_archives',
        component: pages.PersonArchives,
        needPermission: true,
        breadcrumb: [
          {
            text: '液化气充装管理'
          },
          {
            text: '人员档案'
          },
        ],
      },
      {
        path: "/marketUserManage",
        name: '/marketUserManage',
        component: pages.Manage.UserManage.Market,
        needPermission: true,
        breadcrumb: [
          {
            text: '农贸市场管理'
          },
          {
            text: '组织架构管理'
          },
          {
            text: '用户管理'
          },
        ],
      },
      {
        path: "/gasUserManage",
        name: '/gasUserManage',
        component: pages.Manage.UserManage.Gas,
        needPermission: true,
        breadcrumb: [
          {
            text: '液化气充装管理'
          },
          {
            text: '组织架构管理'
          },
          {
            text: '用户管理'
          },
        ],
      },
      {
        path: "/marketDepartment",
        name: '/marketDepartment',
        component: pages.CompanyArchives.Market,
        needPermission: true,
        breadcrumb: [
          {
            text: '农贸市场管理'
          },
          {
            text: '组织架构管理'
          },
          {
            text: '经营单位管理'
          },
        ],
      },
      {
        path: "/gasDepartment",
        name: '/gasDepartment',
        component: pages.CompanyArchives.Gas,
        needPermission: true,
        breadcrumb: [
          {
            text: '液化气充装管理'
          },
          {
            text: '组织架构管理'
          },
          {
            text: '经营单位管理'
          },
        ],
      },
      {
        path: "/market",
        name: '/market',
        component: pages.Market,
        needPermission: true,
        breadcrumb: [
          {
            text: '农贸市场管理'
          },
          {
            text: '概览'
          },
        ],
      },
      {
        path: "/gas",
        name: '/gas',
        component: pages.Gas,
        needPermission: true,
        breadcrumb: [
          {
            text: '液化气充装管理'
          },
          {
            text: '概览'
          },
        ],
      },
      {
        path: "/home",
        name: '/home',
        component: pages.Home,
        needPermission: true,
        breadcrumb: [
          {
            text: '首页'
          },
        ],
      },
      {
        path: "/errorTest",
        name: '/errorTest',
        component: pages.ErrorTest,
        breadcrumb: [
          {
            text: '错误页面跳转'
          },
        ],
      },
      {
        path: "/403",
        name: '/403',
        component: pages._403,
        breadcrumb: [
          {
            text: "403"
          },
        ]
      },
      {
        path: "/404",
        name: '/404',
        component: pages._404,
        breadcrumb: [
          {
            text: "404"
          },
        ]
      },
    ]
  },
];

import React from 'react'
import Template from './template'

const Market = () => <Template type="1" />
const Gas = () => <Template type="2" />

export default {
  Market,
  Gas
}

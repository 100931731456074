import ajax from './ajax'
import character from './character'
import { getToken, getRToken, setToken, setRToken, setID, getID, removeToken } from './cookie'
import { encryptData, rsaUtil, aesUtil } from './encryption'


// 文件大小转换
export function toSizeText(size) {
  let num = 0
  let unit = ''
  if (size < 1000) {
    num = size
    unit = 'B'
  } else if (size / 1024 < 1000) {
    num = size / 1024
    unit = 'KB'
  } else if (size / 1024 / 1024 < 1000) {
    num = size / 1024 / 1024
    unit = 'MB'
  } else {
    num = size / 1024 / 1024 / 1024
    unit = 'GB'
  }
  num = Math.floor(num * 100) / 100
  return `${num}${unit}`
}

// 获取localStorage的已存容量
export function getLocalSurplus() {
  if (!window.localStorage) {
    console.log('浏览器不支持localStorage');
  }
  var size = 0;
  for (let item in window.localStorage) {
    if (window.localStorage.hasOwnProperty(item)) {
      size += window.localStorage.getItem(item).length;
    }
  }

  if (parseInt((size / 1024).toFixed(2)) > 4000) {
    window.localStorage.clear()
  }
  console.log('当前localStorage已存容量为' + (size / 1024).toFixed(2) + 'KB');
}

export function getQueryString(h, name) {
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  let r = h.substr(1).match(reg);
  if (r != null) return decodeURIComponent(r[2]);
  return null;
}

// 获取地址栏搜索参数 search 格式，如： ?deal_status=2
export function getParams(search) {
  let result = {}
  let searchParamsStr = search.split('?')[1]
  if (searchParamsStr) {
    let searchParams = searchParamsStr.split('&')
    searchParams.forEach(elem => {
      let arr = elem.split('=')
      let key = arr[0]
      let value = arr[1]
      if (key) {
        result[key] = decodeURIComponent(value)
      }
    })
  }
  return result
}

// 生成一个唯一随机数
export function createRandom() {
  return new Date().getTime() + '_' + parseInt(Math.random() * 10000) + '_' + parseInt(Math.random() * 10000)
}

// 输入框输入整数处理
export function integerHandle(value) {
  if (isNaN(parseInt(value))) {
    return ''
  } else {
    return parseInt(value)
  }
}

// input输入框的字符禁用
export function handleKeyPress(event) {
  const forbiddenCharacters = ['-', '+', '.', 'e', 'E']
  if (forbiddenCharacters.indexOf(event.key) !== -1) {
    event.preventDefault()
  }
}

//校验身份证号
export function idCardTest(value) {
  let re = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
  return re.test(value)
}
// 开启前进后退事件
export function mouseTools(min) {

  let x = 0
  let tag = false
  let move = false

  window.addEventListener('mousedown', function (e) {
    e = window.event || e
    if (e.which == 3) {
      x = e.pageX
      tag = true
    }
  })


  window.addEventListener('contextmenu', function (e) {
    if (move) {
      e = window.event || e
      move = false
      e.preventDefault()
      return false;
    }
  })

  window.addEventListener('mouseup', function (e) {
    e = window.event || e
    if (e.which == 3 && tag) {
      tag = false
      let movex = e.pageX - x
      if (Math.abs(movex) > min) {
        move = true
        if (movex > 0) {
          window.history.go(1)
        } else {
          window.history.back()
        }
      }
    }
  })
}
// 禁用常规快捷键
export function disableKeyDown() {
  const keyDown = (e) => {
    // 禁用f12
    const f12 = e.keyCode == 123 || e.key === "F12" || (e.ctrlKey && e.shiftKey && e.keyCode == 73)
    // 禁用wx截图
    const wxScreenshot = e.altKey && e.shiftKey && e.keyCode == 65
    if (f12 || wxScreenshot) {
      e.preventDefault();
    }
  }
  window.addEventListener('keydown', keyDown);
  return () => {
    window.removeEventListener('keydown', keyDown);
  }
}
// 根据身份证计算年龄
export function isIDCard(id) {
  var reg = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}([0-9]|X)$/;
  return reg.test(id);
}

export function calculateAgeByIDCard(idCard) {
  if (!isIDCard(idCard)) {
    return "无效的身份证号码";
  }
  const birthYear = idCard.slice(6, 10);
  const currentYear = new Date().getFullYear();
  return currentYear - birthYear;
}
// 密码格式策略   rules_type: 规则类型
export function pwdRegStrategy(value, rules_type) {
  let regExp1 = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]).+$/;
  let regExp2 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\s\S]+$/;
  let regExp3 = /^(?=.*?[A-Z])(?=.*?[a-z]).+$/;
  // 0-大小写字母加数字加特殊字符 1-大小写字母加数字 2-大小写字母
  switch (rules_type) {
    case "0":
      return regExp1.test(value);
    case "1":
      return regExp2.test(value);
    case "2":
      return regExp3.test(value);
    default:
      return false;
  }
}
// 密码字符串，保留大小写字母、数字、特殊字符
export function pwdString(str) {
  return str.replace(
    /([^A-Za-z0-9`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、])/g,
    ""
  )
}
// 密码强度策略   type: 0-大小写字母+数字+特殊字符, 1-大小写字母+数字, 2-大小写字母
export function pwdTest(value, type) {
  let regExp0 = /^(((?=.*[0-9])(?=.*[a-zA-Z])(?=.*[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]))[^\s]+)$/g;
  let regExp1 = /^(((?=.*[0-9])(?=.*[a-zA-Z]))[^\s]+)$/g;
  let regExp2 = /^((?=.*[a-zA-Z])[^\s]+)$/g;

  switch (type) {
    case 0:
      return regExp0.test(value);
    case 1:
      return regExp1.test(value);
    case 2:
      return regExp2.test(value);
    default:
      return false;
  }
}

// 各种正则表达式
const regular = {
  zh: /[\u4e00-\u9fa5]/g, // 匹配到中文
  zhBlank: /[\u4e00-\u9fa5]|\s/g, // 匹配到中文+空格,
  ip: /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/,//ip地址
  port: /^([0-9]|[1-9]\d{1,3}|[1-5]\d{4}|6[0-5]{2}[0-3][0-5])$/,
  ipPort: /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])(:([0-9]|[1-9]\d{1,3}|[1-5]\d{4}|6[0-5]{2}[0-3][0-5])){0,1}$/, // iP/ip+端口
  isNum: /^[0-9]*$/,
  isPhone: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, //手机号
  isTel: /^(\d{2,3}-\d{7,8}|\(\d{2,3}[)-]*\d{7,8})|(\d{2,3}\d{7,8})$/  // 座机
}


export {
  ajax,
  character,
  getToken, getRToken, setToken, setRToken, setID, getID, removeToken,
  encryptData, rsaUtil, aesUtil,
  regular,
}

import React, { useEffect, useState } from 'react'
import { Modal, Message } from '@yisa/webui'
import { FormInput, FormSelect } from '@/components';
import ajax from '@/services'

function EditModal(props) {
  const {
    data,
    visible,
    onSuccess,
    onClose
  } = props

  const { id } = data || {}
  const [editData, setEditData] = useState(null)
  const [supervisoryPerson, setSupervisoryPerson] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setEditData(data || null)
  }, [data])

  useEffect(() => {
    id && getSuperviseList()
  }, [id])

  const getSuperviseList = () => {
    ajax.getSuperviseList({ id: id }).then(res => {
      setSupervisoryPerson(Array.isArray(res?.data) ? res.data : [])
    }).catch(err => console.log(err))
  }

  const handleSubmit = () => {
    const { business_name, business_person, social_credit_code, business_tel, business_type, license_number,
      network_bandwidth, device_num, supervisory_person, supervisory_tel
    } = editData || {}
    if (!business_name || !business_person || !social_credit_code || !business_tel
      || !business_type || !license_number || !network_bandwidth || !device_num
      || !supervisory_person || !supervisory_tel) return Message.warning("请填写必填项！")
    setLoading(true)
    ajax.organizationEdit(editData).then(res => {
      Message.success(res.message)
      onSuccess && onSuccess()
    }).catch(err => {
      console.log(err)
      Message.error('编辑失败')
    }).finally(() => {
      setLoading(false)
    })
  }

  const handleCancel = () => {
    onClose && onClose()
  }

  return (
    <Modal
      title="编辑部门"
      className='department-modal'
      visible={visible}
      width={690}
      maskClosable={false}
      escToExit={false}
      okText="提交"
      cancelText="取消"
      onOk={handleSubmit}
      onCancel={handleCancel}
      okButtonProps={{
        loading: loading
      }}
      cancelButtonProps={{
        disabled: loading
      }}
    >
      <div className="save-content">
        <div className="search-box">
          <FormInput
            className="search-item"
            yisaLabel="经营单位名称"
            placeholder="请输入经营单位名称"
            isRequired
            disabled={true}
            value={editData?.business_name}
            onChange={v => setEditData({
              ...editData,
              business_name: v.target.value
            })}
          />
          <FormInput
            className="search-item"
            yisaLabel="联系人"
            placeholder="请输入联系人姓名"
            isRequired
            value={editData?.business_person}
            onChange={v => setEditData({
              ...editData,
              business_person: v.target.value
            })}
          />
          <FormInput
            className="search-item"
            yisaLabel="社会信用代码"
            placeholder="请输入社会信用代码"
            isRequired
            value={editData?.social_credit_code}
            onChange={v => setEditData({
              ...editData,
              social_credit_code: v.target.value
            })}
          />
          <FormInput
            className="search-item"
            yisaLabel="联系方式"
            placeholder="请输入联系方式"
            isRequired
            value={editData?.business_tel}
            onChange={v => setEditData({
              ...editData,
              business_tel: v.target.value
            })}
          />
          <FormSelect
            labelPosition="top"
            yisaLabel="经营单位类别"
            isRequired
            allowClear={false}
            options={[{
              label: "农贸市场",
              value: "1",
            }, {
              label: "液化气单位",
              value: "2",
            }]}
            disabled={true}
            className="search-item"
            placeholder="请选择经营单位类别"
            value={editData?.business_type}
            onChange={v => setEditData({
              ...editData,
              business_type: v
            })}
          />
          <FormInput
            className="search-item"
            yisaLabel="许可证号"
            placeholder="请输入许可证号"
            isRequired
            value={editData?.license_number}
            onChange={v => setEditData({
              ...editData,
              license_number: v.target.value
            })}
          />
          <FormInput
            className="search-item"
            yisaLabel="网络带宽"
            placeholder="请输入网络带宽"
            isRequired
            value={editData?.network_bandwidth}
            onChange={v => setEditData({
              ...editData,
              network_bandwidth: v.target.value
            })}
          />
          <FormInput
            className="search-item"
            yisaLabel="设备数量"
            placeholder="请输入设备数量"
            isRequired
            value={editData?.device_num}
            onChange={v => setEditData({
              ...editData,
              device_num: v.target.value
            })}
          />
          <FormSelect
            allowClear={false}
            labelPosition="top"
            yisaLabel="监管单位负责人"
            options={supervisoryPerson}
            isRequired
            className="search-item"
            placeholder="请选择监管单位负责人"
            value={editData?.supervisory_person}
            onChange={(v) => {
              let { tel, unit_id } = supervisoryPerson.filter(e => e.value === v)[0] || {}
              setEditData({
                ...editData,
                supervisory_person: v,
                supervisory_tel: tel,
                supervisory_unit: unit_id
              })
            }} />
          <FormInput
            className="search-item"
            yisaLabel="负责人联系方式"
            placeholder="请输入负责人联系方式"
            isRequired
            value={editData?.supervisory_tel}
            onChange={v => setEditData({
              ...editData,
              supervisory_tel: v.target.value
            })}
          />
        </div>
      </div>
    </Modal>
  )
}

export default EditModal

import { ajax } from '@/utils'
import list from './list'
import overview from './overview'

const isDev = process.env.NODE_ENV === "development"

const api = {
  common: {
  },

  // 获取用户信息
  getSysConfig: function () {
    return ajax({
      method: "get",
      url: '/common/get-sys-config',
      params: {}
    });
  },


  // 错误信息上报
  postError: function (url, data) {
    return ajax({
      method: "post",
      url: url,
      data: data
    });
  },

  // 修改主题和布局
  changeStyle: function (data) {
    return ajax({
      method: "get",
      url: '/change_style',
      params: data
    });
  },

  // 获取用户信息
  getUserInfo: function () {
    return ajax({
      method: "get",
      url: '/common/get-user-info',
      params: {}
    });
  },
  login: function (data) {
    return ajax({
      method: "post",
      url: '/user/login',
      params: data
    });
  },
  // 修改密码
  doEditPwd: function (data) {
    return ajax({
      method: "post",
      url: '/user/update-password',
      params: data
    });
  },
  // 预警
  getWarningMsg: function () {
    return ajax({
      method: "get",
      url: '/warning/msg',
      params: {}
    });
  },
  warningRead: function (data) {
    return ajax({
      method: "get",
      url: '/warning/read',
      params: data
    });
  },
  // 退出登录
  logout: function () {
    return ajax({
      method: "post",
      url: '/user/logout',
      data: {}
    });
  },
  // 获取经营单位下拉
  getBusiness: function (data) {
    return ajax({
      method: 'get',
      url: '/common/get-business-list',
      params: data
    })
  },
  // 获取抓拍设备
  getDeviceCapture: function (data) {
    return ajax({
      method: 'get',
      url: '/common/get-device-capture',
      params: data
    })
  },
  // 获取网格
  getGridList: function (data) {
    return ajax({
      method: 'get',
      url: '/common/get-grid-list',
      params: data
    })
  },
  // 获取验证码图片
  getVerifyImg: function (data) {
    return ajax({
      method: 'get',
      url: '/common/verify-code',
      params: data
    })
  },

  // 验证验证码
  getVerifyNum: function (data) {
    return ajax({
      method: 'post',
      url: '/common/check-verify-code',
      params: data
    })
  },
  // 导航菜单
  getLayoutHeaderMenuList: (data) => {
    return ajax({
      url: isDev ? '/common/get_menu_list' : "http://27.195.3.124:8082/IAM/v1/admin/overview/get_menu_list",
      params: data,
      type: "get"
    });
  },
  // 获取违规行为
  getViolationType: function (data) {
    return ajax({
      method: 'get',
      url: '/common/get-violation-type',
      params: data
    })
  },
}

export default {
  ...api,
  ...list,
  ...overview
}

import React from "react";
import { Input } from "@yisa/webui";
import classnames from "classnames";
import "./index.scss";
const { InputNumber } = Input

export default function FormInputGroup(props) {
  const {
    yisaLabel = "",
    labelPosition = "top",
    onChange = () => { },
    type = "number",
    defaultValueMax,
    defaultValueMin,
    splitText = "~",
    placeholder = ['请输入', '请输入'],
    className = "",
    isRequired = false,  // 标题前的*
  } = props;
  const handleMinChange = (e) => {
    const v = type == 'number' ? e : e.target.value
    if (onChange) {
      onChange({
        max: defaultValueMax,
        min: v < 0 || !v ? 0 : v
      })
    }
  }

  const handleMaxChange = (e) => {
    const v = type == 'number' ? e : e.target.value
    if (onChange) {
      onChange({
        min: defaultValueMin,
        max: v || 0
      })
    }
  }
  return (<div
    className={classnames({
      "cc-form-input-groups": true,
      "form-input-groups-top": labelPosition == "top",
      "form-input-groups-left": labelPosition == "left",
      [className]: className != "",
    })}>
    {yisaLabel && (
      <div className="cc-form-name">
        {isRequired ? <label className="required">*</label> : null}
        <label>{yisaLabel}</label>
      </div>
    )}
    <div className="cc-form-content">
      <Input.Group compact>
        {type == 'number' ?
          <InputNumber placeholder={placeholder[0]} value={defaultValueMin} onChange={handleMinChange} /> :
          <Input placeholder={placeholder[0]} value={defaultValueMin} onChange={handleMinChange} />
        }

        <span className='input-split'>{splitText}</span>
        {type == 'number' ?
          <InputNumber placeholder={placeholder[1]} value={defaultValueMax} onChange={handleMaxChange} /> :
          <Input placeholder={placeholder[1]} value={defaultValueMax} onChange={handleMaxChange} />}
      </Input.Group>
    </div>
  </ div>

  );
}

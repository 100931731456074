import React from "react";
import { Select } from "@yisa/webui";
import classnames from "classnames";
import "./index.scss";

const { Option } = Select;
export default function FormSelect(props) {
  const {
    yisaLabel = "",
    labelPosition = "top",
    options = [],
    allowClear = true,
    showSearch = true,
    onChange = () => { },
    placeholder = "请选择",
    value = undefined,
    disabled = false,
    className = "",
    multiple = false,
    maxTagCount = 1,
    filterOption = (input, option) => {
      if (option.children.includes(input)) {
        return true;
      }
    },
    getPopupContainer = (triggerNode) => triggerNode.parentElement,
    showUnlimit = false,
    isRequired = false,  // 标题前的*
    dropdownMatchSelectWidth = true,
    fieldNames = {
      label: 'label',
      value: 'value'
    }
  } = props;

  const handleOnChange = (v, option) => {
    onChange(v == undefined ? '' : v, option);
  };

  return (
    <div
      className={classnames({
        "cc-form-select": true,
        "form-select-top": labelPosition == "top",
        "form-select-left": labelPosition == "left",
        [className]: className != "",
      })}
    >
      {yisaLabel && (
        <div className="cc-form-name">
          {isRequired ? <label className="required">*</label> : null}
          <label>{yisaLabel}</label>
        </div>
      )}
      <div className="cc-form-content">
        <Select
          allowClear={allowClear}
          onChange={handleOnChange}
          value={value || undefined}
          placeholder={placeholder}
          showSearch={showSearch}
          maxTagCount={maxTagCount}
          mode={multiple ? "multiple" : "single"}
          disabled={disabled}
          filterOptions={filterOption}
          getTriggerContainer={getPopupContainer}
          dropMenuMatchWidth={dropdownMatchSelectWidth}
        >
          {showUnlimit && (
            <Option key={"-1"} value={"-1"}>
              全部
            </Option>
          )}
          {options?.map((item, index) => {
            return (
              <Option key={index} value={item[fieldNames.value]} className={item?.className || ''}>
                {item[fieldNames.label]}
              </Option>
            );
          })}
        </Select>
      </div>
    </div>
  );
}

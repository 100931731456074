import React, { useState, useEffect } from 'react';
import { Table, Button, Pagination, Message, Modal, PopConfirm } from '@yisa/webui';
import { ResultFlow, FormInput, FormTreeSelect, FormSelect } from '@/components';
import { useBoolean } from "ahooks";
import { character } from "@/utils";
import ajax from '@/services';
import './index.scss'

function UserManage(props) {
  const {
    type
  } = props

  // 原始表单
  const defaultFormData = {
    module_type: type,
    // 用户名
    user_name: "",
    // 姓名
    name: "",
    // 联系方式
    tel: "",
    // 所属单位
    business_id: [],
    // 角色
    role: "",
    // 页码
    page: 1,
    // 数据量
    page_size: character.pageSizeOptions[0],
  }

  // 编辑弹窗
  const [editVisible, setEditVisible] = useState({
    visible: false,
    data: {}
  })
  const [editViewVisible, setEditViewVisible] = useState(false);
  useEffect(() => {
    setEditViewVisible(editVisible.visible);
  }, [editVisible.visible])


  const [formData, setFormData] = useState(defaultFormData)
  const [lastFormData, setLastFormData] = useState(formData)
  const [loading, { setTrue, setFalse }] = useBoolean(true)
  const [modalLoad, setModalLoad] = useState(false)
  const [tableScrollY, setTableScrollY] = useState(200);
  const [exportVisible, setExPortVisible] = useState({
    visible: false,
    data: {}
  })
  const [exportViewVisible, setExportViewVisible] = useState(false);
  useEffect(() => {
    setExportViewVisible(exportVisible.visible);
  }, [exportVisible.visible])
  // 经营单位列表
  const [businessList, setBusinessList] = useState([])
  // 获取单位列表
  const [unitList, setUnitList] = useState([])
  // 角色
  const [roleList, setRoleList] = useState([])
  // 表单修改
  const changeValue = (v, k) => {
    setFormData(Object.assign({}, formData, { [k]: v }))
  }
  const [resultData, setResultData] = useState({
    list: [],
    total: 0
  })
  const columns = [
    {
      title: "序号",
      dataIndex: "index",
      width: 60,
      align: "center",
      render: (_, __, index) =>
        <span>{formData.page_size * (formData.page - 1) + index + 1}</span>
    },
    {
      title: "用户名",
      dataIndex: "user_name",
      align: "center",
    },
    {
      title: "姓名",
      dataIndex: "name",
      align: "center",
    },
    {
      title: "所属单位",
      dataIndex: "business_name_text",
      align: "center",
    },
    {
      title: "联系方式",
      dataIndex: "tel",
      align: "center",
    },
    {
      title: "角色",
      dataIndex: "role_text",
      align: "center",
    },
    {
      title: "操作",
      dataIndex: "capture_correct_num",
      align: "center",
      render: (text, record) => {
        return <><span className="edit-btn" onClick={() => {
          setEditVisible({
            visible: true,
            data: { ...record, type: "edit" }
          })
          getRoleList(record.business_name)
        }}>编辑</span>
          <PopConfirm
            title={<span>确认要重置该用户密码吗？</span>}
            placement="left"
            onConfirm={() => resetUser(record.id)}
          >
            <span className="edit-btn" onClick={() => {
            }}>重置密码</span>
          </PopConfirm>
          <PopConfirm
            title={<span>确认要删除这条内容吗？</span>}
            placement="left"
            onConfirm={() => delUser(record.id)}
          >
            <span className="del-btn" onClick={() => {
            }}>删除</span>
          </PopConfirm>
        </>
      }
    }

  ]
  // 删除用户
  const delUser = (id) => {
    ajax.getUserDel({
      id: id,
      module_type: type
    }).then(res => {
      if (res.status === 20000) {
        Message.success(res.message)
        getList(defaultFormData)
      } else {
        Message.error(res.message)
      }
      setFalse(false)
    }).catch(err => console.log(err))

  }
  // 重置密码
  const resetUser = (id) => {
    ajax.resetUser({
      id: id,
      module_type: type
    }).then(res => {
      if (res.status === 20000) {
        Message.success(res.message)
        // getList(defaultFormData)
      } else {
        Message.error(res.message)
      }
      setFalse(false)
    }).catch(err => console.log(err))

  }

  // 获取列表数据
  const getList = (params = formData) => {
    setTrue(true)
    setLastFormData(params)
    ajax.getUserList(params).then(res => {
      if (res.status === 20000) {
        setResultData(res.data)
      } else {
        Message.error(res.message)
      }
      setFalse(false)
    }).catch(err => console.log(err))
  }
  // 获取接收部门
  const getUnitList = () => {
    ajax
      .getUnitList({
        module_type: type
      })
      .then((res) => {
        const { status, message, data } = res;
        if (status === 20000) {
          setUnitList(data);
          let arr = [{ label: "全部", value: "-1", children: [...data] }]
          setBusinessList(arr);
        } else {
          Message.error(message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getRoleList = (id) => {
    ajax
      .getRoleList({
        id,
        module_type: type
      })
      .then((res) => {
        const { status, message, data } = res;
        if (status === 20000) {
          setRoleList(data);
        } else {
          Message.error(message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const changeTime = () => {
    let obj = {
      module_type: type,
      id: editVisible.data.id,
      type: editVisible.data.type,
      user_name: editVisible.data.user_name,
      tel: editVisible.data.tel,
      name: editVisible.data.name,
      business_name: editVisible.data.business_name,
      role: editVisible.data.role,
    }
    if (!obj.user_name || !obj.tel || !obj.name || !obj.business_name || !obj.role) return Message.warning("请填写必填项！")

    ajax.userEdit(obj).then(res => {
      if (res.status === 20000) {
        setEditViewVisible(false);
        setTimeout(() => {
          setEditVisible({
            visible: false,
            data: {}
          })
          setRoleList([])
          getList(lastFormData)
        }, 1000);
      } else {
        Message.error(res.message)
      }
      setFalse(false)
    }).catch(err => console.log(err))
  }

  useEffect(() => {
    getList(formData)
    // getBusinessList()
    getUnitList()
  }, [])

  const reset = () => {
    setFormData(defaultFormData)
    getList(defaultFormData)
  }
  const handleBeforeUpload = (file, filesList) => {
    setModalLoad(true)
    if (file?.size > 200 * 1024 * 1024) {
      Message.error('请上传小于200M的文件')
      setModalLoad(false)
      return false
    }
  }
  useEffect(() => {
    const handleCalcHeight = () => {
      try {
        const resultHeight = document.querySelector(".table-box")
          ? document.querySelector(".table-box").clientHeight - 90
          : 0;
        const thHeight = document.querySelector(".ysd-table-header")
          ? document.querySelector(".ysd-table-header").clientHeight
          : 0;
        const toolHeight = 32 + 20;
        const height = resultHeight - thHeight - toolHeight;
        setTableScrollY(Math.max(height, 200));
      } catch (err) {
        console.log(err);
      }
    };
    handleCalcHeight();
    window.addEventListener("resize", handleCalcHeight);

    return () => {
      window.removeEventListener("resize", handleCalcHeight);
    };
  }, [resultData.total]);

  return <div className="user-manage-page">
    <div className="search">
      <div className="title">查询条件</div>
      <FormInput
        className="search-item"
        yisaLabel="用户名"
        placeholder="请输入用户名"
        value={formData.user_name}
        onChange={(v) => changeValue(v.target.value, "user_name")}
      />

      <FormInput
        className="search-item"
        yisaLabel="姓名"
        placeholder="请输入姓名"
        value={formData.name}
        onChange={(v) => changeValue(v.target.value, "name")}
      />
      <FormInput
        className="search-item"
        yisaLabel="联系方式"
        placeholder="请输入联系方式"
        value={formData.tel}
        onChange={(v) => changeValue(v.target.value, "tel")}
      />
      <FormTreeSelect
        labelPosition="top"
        yisaLabel="所属单位"
        placeholder="请选择所属单位"
        className="search-item"
        value={formData.business_id}
        onChange={(v) => changeValue(v, "business_id")}
        fieldNames={{ title: "label", key: "value" }}
        treeData={businessList}
      />
      <FormSelect
        labelPosition="top"
        yisaLabel="角色"
        showClose
        options={character.roleType}
        className="search-item"
        placeholder="请选择角色"
        showUnlimit={true}
        value={formData.role}
        onChange={(v) => changeValue(v, "role")}
      />
      <div className="search-btn-box">
        <Button className="reset-btn" onClick={reset}>
          重置
        </Button>
        <Button type="primary" className='search-btn' onClick={() => getList(formData)}>
          搜索
        </Button>

      </div>
    </div>
    <div className="table-box">
      <nav className="total">
        <p>
          共<span> {resultData.total || 0} </span>条结果
        </p>
        <Button className="add-user-btn" type="primary" onClick={() => setEditVisible({
          visible: true,
          data: {
            type: "add"
          }
        })}>
          新增用户
        </Button>
      </nav>

      <ResultFlow
        loading={loading}
        resultData={resultData.list}
      >
        <div className="list">
          <Table
            className="table-warp"
            columns={columns}
            data={resultData.list}
            rowKey="id"
            bordered
            scroll={{
              y: tableScrollY
            }}
            loading={loading}
            pagination={false}
          />
        </div>
        <Pagination
          className="pagination-common"
          showQuickJumper
          showSizeChanger
          size='default'
          position={['bottomCenter']}
          current={lastFormData.page}
          total={resultData.total}
          pageSize={lastFormData.page_size}
          onChange={(page, pageSize) => {
            let obj = {};
            if (pageSize !== lastFormData.page_size) {
              obj.page = 1
              obj.page_size = pageSize
            } else {
              obj.page = page
            }
            let newParams = Object.assign({}, lastFormData, obj);
            getList(newParams)
          }}
          pageSizeOptions={character.pageSizeOptions}
        />
      </ResultFlow>
    </div>
    <Modal
      title={editVisible.data.type === "add" ? '添加用户' : "编辑"}
      className='user-modal'
      loading={modalLoad}
      // visible={editVisible.visible}
      visible={editViewVisible}
      width={690}
      maskClosable={false}
      okText="提交"
      cancelText="取消"
      onOk={() => {
        changeTime()
      }}
      onCancel={() => {
        setEditViewVisible(false);
        setTimeout(() => {
          setEditVisible({
            visible: false,
            data: {}
          })
          setRoleList([])
        }, 1000);
      }}
    >
      <div className="save-content">
        <div className="title-name">单个人员信息录入</div>
        <div className="search-box">
          <FormInput
            className="search-item"
            yisaLabel="用户名"
            placeholder="请输入用户名"
            isRequired
            value={editVisible.data.user_name}
            onChange={(v) => setEditVisible({
              visible: true,
              data: {
                ...editVisible.data,
                user_name: v.target.value
              }
            })}
          />
          <FormInput
            className="search-item"
            yisaLabel="联系方式"
            placeholder="请输入联系方式"
            isRequired
            value={editVisible.data.tel}
            onChange={(v) => setEditVisible({
              visible: true,
              data: {
                ...editVisible.data,
                tel: v.target.value
              }
            })}
          />
          <FormInput
            className="search-item"
            yisaLabel="姓名"
            placeholder="请输入姓名"
            isRequired
            value={editVisible.data.name}
            onChange={(v) => setEditVisible({
              visible: true,
              data: {
                ...editVisible.data,
                name: v.target.value
              }
            })}
          />
          <FormTreeSelect
            labelPosition="top"
            yisaLabel="所属单位"
            placeholder="请选择所属单位"
            className="search-item"
            allowClear={false}
            value={editVisible.data.business_name}
            isRequired
            multiple={false}
            onChange={(v) => {
              getRoleList(v)
              setEditVisible({
                visible: true,
                data: {
                  ...editVisible.data,
                  business_name: v,
                  role: ""
                }
              })
            }}
            fieldNames={{ title: "label", key: "value" }}
            treeData={unitList}
          />
          <FormSelect
            labelPosition="top"
            yisaLabel="角色"
            showClose
            isRequired
            allowClear={false}
            options={roleList}
            className="search-item"
            placeholder="请选择角色"
            value={editVisible.data.role}
            onChange={(v) => setEditVisible({
              visible: true,
              data: {
                ...editVisible.data,
                role: v
              }
            })}
          />
        </div>
        {/* {uploadRender()} */}
      </div>
    </Modal>
    <Modal
      title="导入结果"
      hideCancel={true}
      className='export-user-modal'
      visible={exportViewVisible}
      width={650}
      maskClosable={false}
      okText="取消"
      onOk={() => {
        setExportViewVisible(false);
        setTimeout(() => {
          setEditVisible({
            visible: false,
            data: {}
          })
          setExPortVisible({
            visible: false,
            data: {}
          })
          getList(formData)
        }, 1000)
      }}
      onCancel={() => {
        setExportViewVisible(false);
        setTimeout(() => {
          getList(formData)
          setExPortVisible({
            visible: false,
            data: {}
          })
        }, 1000)
      }}
    >
      <div className="export-user-modal-content">

        <div className="title">{exportVisible.data.message}</div>
        {
          exportVisible.data.data && exportVisible.data.data.err_file_url ? <a
            className="ant-btn-primary"
            href={exportVisible.data.data.err_file_url}
            target="_blank"
          >
            失败数据下载
          </a> : null
        }

      </div>
    </Modal>
  </div>
}

export default UserManage

import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { setUser } from '@/store/user';
import { useHistory } from 'react-router';
import { initSkinLayout } from '@/store/comment';
import APPRouter from '@/router'
import { Message } from '@yisa/webui';
import { ConfigContext } from '@yisa/webui_business';
import { getToken, setID, aesUtil, mouseTools } from '@/utils'
import ajax from '@/services'


function App() {

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true)

  const errorHandel = (err) => {
    setLoading(false)
    Message.error(err.message || '未知错误');
  }

  useEffect(() => {

    mouseTools(100)

    ajax.getSysConfig().then((res) => {
      if (typeof res.data === 'string') {
        res.data = aesUtil.decrypt(res.data)
      }
      window.sysConfig = res.data
      if (getToken()) {
        ajax.getUserInfo().then((res) => {
          dispatch(setUser(res.data))
          dispatch(initSkinLayout(res.data))
          if (res.data && res.data.user_info) {
            setID(res.data.user_info.id)
            window.user_id = res.data.user_info.id
          }
          setLoading(false)
        }).catch((err) => {
          errorHandel(err)
        });
      } else {
        setLoading(false)
        window.location = window.location.origin + "/#/login"
      }
    }).catch((err) => {
      errorHandel(err)
    });


  }, []);

  return (
    loading ?
      <div className="full-loading"></div> :
      <ConfigContext.Provider value={{
        province: window.sysConfig.province,
        isShowColor: true,
        resetOnChange: true,
      }}>
        <APPRouter />
      </ConfigContext.Provider>
  );
}

export default App





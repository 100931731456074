import React, { useEffect, useState } from 'react';
import { Message, Modal } from '@yisa/webui';
import { FormInput, OrgTree, FormSelect } from '@/components';
import ajax from '@/services';
import './index.scss'

function Department(props) {
  const {
    type
  } = props

  // 选中的id
  const [checkId] = useState("");
  // 刷新
  const [refresh, setRefresh] = useState(false)
  // 新增编辑部门弹窗
  const [editVisible, setEditVisible] = useState({
    visible: false,
    data: {}
  })
  const [editViewVisible, setEditViewVisible] = useState(false);

  useEffect(() => {
    setEditViewVisible(editVisible.visible);
  }, [editVisible.visible])

  // 删除弹窗
  const [delVisible, setDelVisible] = useState({
    visible: false,
    data: {}
  })
  const [delViewVisible, setDelViewVisible] = useState(false);
  useEffect(() => {
    setDelViewVisible(delVisible.visible);
  }, [delVisible.visible])
  // 监管单位负责人
  const [supervisoryPerson, setSupervisoryPerson] = useState([])

  // 点击事件
  const departOnClick = (type, data, parent_data) => {
    switch (type) {
      case '1': // 新增
        if (data.scale === 3) {
          // 获取监管单位负责人
          getSuperviseList(data.key)
        }
        setEditVisible({
          visible: true,
          data: {
            scale: data.scale,
            parent_id: data.key,
            type: type
          }
        })
        break;
      case '2': // 编辑
        if (data.scale === 4) {
          // 获取经营单位数据
          getBusinessDetail(type, data)
          getSuperviseList(parent_data.key)
        }
        setEditVisible({
          visible: true,
          data: {
            business_name: data.title,
            scale: data.scale,
            parent_id: data.key,
            type: type
          }
        })
        break;
      case '3': // 删除
        setDelVisible({
          visible: true,
          data: {
            scale: data.scale,
            id: data.key,
            children: data.children
          }
        })
        break;
    }
  }
  // 编辑经营单位获取详情
  const getBusinessDetail = (type, data) => {
    ajax.getBusinessDetail({ id: data.key }).then(res => {
      if (res.status === 20000) {
        let obj = res.data.list[0]
        setEditVisible({
          visible: true,
          data: {
            scale: data.scale,
            parent_id: data.key,
            type: type,
            ...obj,
            network_bandwidth: String(obj.network_bandwidth),
            device_num: String(obj.device_num),
          }
        })
      } else {
        Message.error(res.message)
      }
    }).catch(err => console.log(err))
  }
  // 获取监管部门人员
  const getSuperviseList = (id) => {
    ajax.getSuperviseList({ id: id }).then(res => {
      if (res.status === 20000) {
        setSupervisoryPerson(res.data)
      } else {
        Message.error(res.message)
      }
    }).catch(err => console.log(err))
  }
  // 编辑或添加部门
  const onChangeDepart = () => {
    let obj = {
      business_name: editVisible.data.business_name,
      scale: editVisible.data.scale,
    }
    if (editVisible.data.type === '1') {
      obj = { ...obj, parent_id: editVisible.data.parent_id }
    } else {
      obj = { ...obj, id: editVisible.data.parent_id }
    }
    if (!obj.business_name) return Message.warning("请填写单位名称！")

    ajax[editVisible.data.type === '1' ? "organizationAdd" : "organizationEdit"](obj)
      .then(res => {
        if (res.status === 20000) {
          Message.success(res.message)
          setEditViewVisible(false);
          setTimeout(() => {
            setEditVisible({
              visible: false,
              data: {}
            })
            setRefresh(!refresh)
          }, 1000);
        } else {
          Message.error(res.message)
        }
      }).catch(err => console.log(err))
  }
  // 编辑或添加经营单位
  const onChangeBusiness = () => {
    const { business_name, business_person, social_credit_code, business_tel, business_type, license_number,
      network_bandwidth, device_num, supervisory_person, supervisory_tel, supervisory_unit, scale
    } = editVisible.data
    if (!business_name || !business_person || !social_credit_code || !business_tel
      || !business_type || !license_number || !network_bandwidth || !device_num
      || !supervisory_person || !supervisory_tel) return Message.warning("请填写必填项！")

    let obj = {
      business_name: business_name,
      business_person: business_person,
      social_credit_code: social_credit_code,
      business_tel: business_tel,
      business_type: business_type,
      license_number: license_number,
      network_bandwidth: network_bandwidth,
      device_num: device_num,
      supervisory_person: supervisory_person,
      supervisory_tel: supervisory_tel,
      supervisory_unit: supervisory_unit,
      scale: scale,
    }
    if (editVisible.data.type === '1') {
      obj = { ...obj, parent_id: editVisible.data.parent_id }
    } else {
      obj = { ...obj, id: editVisible.data.parent_id }
    }

    ajax[editVisible.data.type === '1' ? "organizationAdd" : "organizationEdit"](obj)
      .then(res => {
        if (res.status === 20000) {
          Message.success(res.message)
          setEditViewVisible(false);
          setTimeout(() => {
            setEditVisible({
              visible: false,
              data: {}
            })
            setRefresh(!refresh)
          }, 1000);
        } else {
          Message.error(res.message)
        }
      }).catch(err => console.log(err))
  }


  // 删除部门
  const delDepart = () => {
    ajax.organizationDel({ id: delVisible.data.id }).then(res => {
      if (res.status === 20000) {
        Message.success(res.message)
        setDelViewVisible(false);
        setTimeout(() => {
          setDelVisible({
            visible: false,
            data: {}
          })
          setRefresh(!refresh)
        }, 1000);
      } else {
        Message.error(res.message)
      }
    }).catch(err => console.log(err))
  }
  return <div className="department-page">
    <OrgTree
      type={type}
      className="bottom-box"
      refresh={refresh}
      checkVal={checkId}
      onClick={departOnClick}
    />
    <Modal
      title={editVisible.data.type === "1" ? '添加部门' : "编辑部门"}
      className='department-modal'
      // visible={editVisible.visible}
      visible={editViewVisible}
      // 判断是不是经营单位
      width={(editVisible.data.type === "1" && editVisible.data.scale === 3) ||
        (editVisible.data.type === "2" && editVisible.data.scale === 4) ? 690 : 352}

      maskClosable={false}
      okText="提交"
      cancelText="取消"
      onOk={() => {
        if ((editVisible.data.type === "1" && editVisible.data.scale === 3) ||
          (editVisible.data.type === "2" && editVisible.data.scale === 4)) {
          onChangeBusiness()
        } else {
          onChangeDepart()

        }
      }}
      onCancel={() => {
        setEditViewVisible(false);
        setTimeout(() => {
          setEditVisible({
            visible: false,
            data: {}
          })
        }, 1000)
      }}
    >
      {
        (editVisible.data.type === "1" && editVisible.data.scale === 3) ||
          (editVisible.data.type === "2" && editVisible.data.scale === 4) ?
          <div className="save-content">
            <div className="search-box">
              <FormInput
                className="search-item"
                yisaLabel="经营单位名称"
                placeholder="请输入经营单位名称"
                isRequired
                disabled={true}
                value={editVisible.data.business_name}
                onChange={(v) => setEditVisible({
                  visible: true,
                  data: {
                    ...editVisible.data,
                    business_name: v.target.value
                  }
                })}
              />
              <FormInput
                className="search-item"
                yisaLabel="联系人"
                placeholder="请输入联系人姓名"
                isRequired
                value={editVisible.data.business_person}
                onChange={(v) => setEditVisible({
                  visible: true,
                  data: {
                    ...editVisible.data,
                    business_person: v.target.value
                  }
                })}
              />
              <FormInput
                className="search-item"
                yisaLabel="社会信用代码"
                placeholder="请输入社会信用代码"
                isRequired
                value={editVisible.data.social_credit_code}
                onChange={(v) => setEditVisible({
                  visible: true,
                  data: {
                    ...editVisible.data,
                    social_credit_code: v.target.value
                  }
                })}
              />
              <FormInput
                className="search-item"
                yisaLabel="联系方式"
                placeholder="请输入联系方式"
                isRequired
                value={editVisible.data.business_tel}
                onChange={(v) => setEditVisible({
                  visible: true,
                  data: {
                    ...editVisible.data,
                    business_tel: v.target.value
                  }
                })}
              />
              <FormSelect
                labelPosition="top"
                yisaLabel="经营单位类别"
                isRequired
                allowClear={false}
                options={[{
                  label: "农贸市场",
                  value: "1",
                }, {
                  label: "液化气单位",
                  value: "2",
                }]}
                disabled={true}
                className="search-item"
                placeholder="请选择经营单位类别"
                value={editVisible.data.business_type}
                onChange={(v) => setEditVisible({
                  visible: true,
                  data: {
                    ...editVisible.data,
                    business_type: v
                  }
                })}
              />
              <FormInput
                className="search-item"
                yisaLabel="许可证号"
                placeholder="请输入许可证号"
                isRequired
                value={editVisible.data.license_number}
                onChange={(v) => setEditVisible({
                  visible: true,
                  data: {
                    ...editVisible.data,
                    license_number: v.target.value
                  }
                })}
              />
              <FormInput
                className="search-item"
                yisaLabel="网络带宽"
                placeholder="请输入网络带宽"
                isRequired
                value={editVisible.data.network_bandwidth}
                onChange={(v) => setEditVisible({
                  visible: true,
                  data: {
                    ...editVisible.data,
                    network_bandwidth: v.target.value
                  }
                })}
              />
              <FormInput
                className="search-item"
                yisaLabel="设备数量"
                placeholder="请输入设备数量"
                isRequired
                value={editVisible.data.device_num}
                onChange={(v) => setEditVisible({
                  visible: true,
                  data: {
                    ...editVisible.data,
                    device_num: v.target.value
                  }
                })}
              />
              <FormSelect
                allowClear={false}
                labelPosition="top"
                yisaLabel="监管单位负责人"
                options={supervisoryPerson}
                isRequired
                className="search-item"
                placeholder="请选择监管单位负责人"
                value={editVisible.data.supervisory_person}
                onChange={(v) => {
                  let obj = supervisoryPerson.filter(e => e.value === v)[0]
                  setEditVisible({
                    visible: true,
                    data: {
                      ...editVisible.data,
                      supervisory_person: v,
                      supervisory_tel: obj.tel,
                      supervisory_unit: obj.unit_id
                    }
                  })
                }} />
              <FormInput
                className="search-item"
                yisaLabel="负责人联系方式"
                placeholder="请输入负责人联系方式"
                isRequired
                value={editVisible.data.supervisory_tel}
                onChange={(v) => setEditVisible({
                  visible: true,
                  data: {
                    ...editVisible.data,
                    supervisory_tel: v.target.value
                  }
                })}
              />
            </div>

          </div> : <div className="save-content">
            <FormInput
              className="search-item1"
              yisaLabel="单位名称"
              placeholder="请输入单位名称"
              isRequired
              value={editVisible.data.business_name}
              onChange={(v) => setEditVisible({
                visible: true,
                data: {
                  ...editVisible.data,
                  business_name: v.target.value
                }
              })}
            />
          </div>
      }

    </Modal>
    <Modal
      className='del-modal'
      // visible={delVisible.visible}
      visible={delViewVisible}
      width={420}
      maskClosable={false}
      okText="确认"
      cancelText="取消"
      onOk={() => {
        delDepart()
      }}
      onCancel={() => {
        setDelViewVisible(false)
        setTimeout(() => {
          setDelVisible({
            visible: false,
            data: {}
          })
        }, 1000);
      }}
    >
      <div className="total">是否确认删除？</div>
      <div className="title">{delVisible.data.children && delVisible.data.children.length > 0 ? "确认要删除该部门及其所有子部门？" : "请确定是否删除该经营单位，删除后该单位档案、设备档案、用户档案等将会被全部删除！"}</div>

    </Modal>
  </div>
}

export default Department

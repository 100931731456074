import React, { useState, useEffect } from "react";
import { DatePicker, Select } from "@yisa/webui";
import classnames from "classnames";
import dayjs from "dayjs";
import "./index.scss";

const { RangePicker } = DatePicker;
export default function FormDateRange(props) {
  const {
    yisaLabel = "",
    labelPosition = "top",
    onChange = () => { },
    value = "",
    disabled = false,
    className = "",
    showDropdown = false,
    format = "YYYY-MM-DD HH:mm:ss",
    showTime = { format: "HH:mm:ss" },
    dropdownData = [
      { label: "今日", value: 0 },
      { label: "昨日", value: 1 },
      { label: "一周", value: 6 },
      { label: "一月", value: 29 },
    ],
    isRequired = false,  // 标题前的*
    ...res
  } = props;
  const [dateRange, setDateRange] = useState(value);
  const onTimeChange = (value, dateString) => {
    setDateRange(dateString);
    onChange(dateString);
  };

  const momentDate = (date) => {
    if (date) {
      return dayjs(date, format);
    } else {
      return null;
    }
  };
  useEffect(() => {
    if (JSON.stringify(value) != JSON.dateRange) {
      setDateRange(value);
    }
  }, [value]);

  // 选中常用时段
  const handleDate = (key) => {
    let beginMoment = dayjs().subtract(key, "days").startOf("day");
    let endMoment = dayjs().subtract(key, "days").endOf("day");
    let nowMoment = dayjs();
    if (key == "1") {
      setDateRange([beginMoment.format(format), endMoment.format(format)]);
      onChange([beginMoment.format(format), endMoment.format(format)]);
    } else {
      setDateRange([beginMoment.format(format), nowMoment.format(format)]);
      onChange([beginMoment.format(format), nowMoment.format(format)]);
    }
  };

  return (
    <div
      className={classnames({
        "cc-form-daterange": true,
        "form-daterange-top": labelPosition == "top",
        "form-daterange-left": labelPosition == "left",
        [className]: className != "",
      })}
    >
      {yisaLabel && <div className="cc-form-name">
        {isRequired ? <label className="required">*</label> : null}
        <label>{yisaLabel}</label>
      </div>
      }
      <div className="cc-form-content">
        <RangePicker
          value={[momentDate(dateRange[0]), momentDate(dateRange[1])]}
          showTime={showTime}
          format={format}
          onChange={onTimeChange}
          disabled={disabled}
          getPopupContainer={(triggerNode) => {
            return triggerNode.parentNode || document.body;
          }}
          {...res}
        />
        {showDropdown ? (
          <div className="date-switch-dropdown">
            <Select
              options={dropdownData}
              bordered={false}
              value=""
              onChange={handleDate}
            />
            <span className="text">常用时段</span>
          </div>
        ) : null}
      </div>
    </div>
  );
}

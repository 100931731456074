import React, { useState, useEffect } from "react";
import { Loading, Message } from "@yisa/webui";
import {
  PlusCircleOutlined,
  EditOutlined,
  CloseCircleOutlined,
} from '@yisa/webui/es/Icon';
import ajax from "@/services";
import "./index.scss";
/**
 * @description 部门管理树结构组件
 * @param {String}      className 页面类名
 * @param {String}      checkVal 选中的id
 * @param {Function}    onClick 点击回调，1-添加单位或部门，2-编辑，3-删除
 */

function OrgTree(props) {
  const { className = "", type, refresh, checkVal, onClick } = props;
  // 树结构加载状态
  const [loading, setLoading] = useState(false);
  // 筛选框
  const [filterText, setFilterText] = useState("");
  // 生成的新树结构
  const [newData, setNewData] = useState([]);
  // 展开的id集合
  const [expandIds, setExpandIds] = useState([]);
  // 选中的id
  const [checkId, setCheckId] = useState("");

  useEffect(() => {
    getTreeData();
  }, [refresh]);

  useEffect(() => {
    if (checkVal && newData.length) {
      readTree(checkVal, newData);
    }
  }, [checkVal]);

  // 获取树结构
  const getTreeData = () => {
    setLoading(true);
    ajax.getOrganizationList({
      module_type: type
    }).then(
      (res) => {
        if (res.status === 20000) {
          if (res.data && res.data.length) {

            if (!checkVal) {
              let ids = getAllIds(res.data)
              setExpandIds(ids);
              // setExpandIds([res.data[0].value]);
              // setCheckId(res.data[0].value);
              // onClick("0", res.data[0]);

            }
            setNewData(handleTreeData(res.data));
          }
        } else {
          Message.error(res.message);
        }
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        Message.error(err);
      }
    );
  };
  // 获取所有ids
  const getAllIds = (data) => {
    const values = [];

    // 递归函数，用于遍历树形结构
    function traverse(node, level = 0) {
      // 只获取前三层的值
      if (level < 3) {
        values.push(node.value);
      }

      // 如果存在子节点，则继续递归遍历
      if (node.children && node.children.length > 0) {
        node.children.forEach(child => {
          traverse(child, level + 1);
        });
      }
    }

    // 遍历整个数据
    data.forEach(node => {
      traverse(node);
    });
    return values;
  }
  // 生成带父节点和层级的树结构
  function handleTreeData(treeData, parent) {
    if (!Array.isArray(treeData) || !treeData.length) {
      setNewData([]);
    }
    treeData.forEach((elem) => {
      // 获取所有父节点和层级
      if (!parent) {
        elem.fid = [];
        elem.fname = [];
      } else {
        elem.fid = [...parent.fid, ...[parent.value]];
        elem.fname = [...parent.fname, ...[parent.title]];
      }
      if (elem.value === checkVal) {
        setExpandIds(elem.fid);
        setCheckId(elem.value);
        onClick("0", elem);
      }
      if (elem.hasOwnProperty("children") && elem.children.length) {
        handleTreeData(elem.children, elem);
      }
    });
    return treeData;
  }

  // 遍历树结构
  function readTree(key, list) {
    list.forEach((elem) => {
      // 获取所有父节点和层级
      if (elem.value === key) {
        let arr = [
          ...expandIds,
          ...(elem.fid.length ? elem.fid : [elem.value]),
        ];
        setExpandIds(Array.from(new Set(arr)));
        setCheckId(elem.value);
        onClick("0", elem);
      } else if (elem.hasOwnProperty("children") && elem.children.length) {
        readTree(key, elem.children);
      }
    });
  }

  // 菜单展开与折叠
  const dropToggle = (item) => {
    if (expandIds.includes(item.value)) {
      setExpandIds(expandIds.filter((i) => i !== item.value));
    } else {
      setExpandIds([...expandIds, ...[item.value]]);
    }
  };

  // 生成树结构
  const renderTree = (data, parent_data) => {
    return data.map((item, index) => {
      const isFolder = item.hasOwnProperty("children") && item.children.length;
      const onlyKey = item.scale + "-" + index;
      let red_title = item.title;
      const context = (
        <>
          {isFolder ? (
            <span
              className={
                expandIds.includes(item.value) ? "btn minus" : "btn plus"
              }
              onClick={() => dropToggle(item)}
            >
              {expandIds.includes(item.value) ? "-" : "+"}
            </span>
          ) : (
            <span className="default"></span>
          )}
          <span className={checkId === item.value ? "selected" : "light"}>
            <span dangerouslySetInnerHTML={{ __html: red_title }} />
          </span>
          <span className="suffix">
            {/* {
              item.scale !== 4 ? <PlusCircleOutlined onClick={() => onClick("1", item)} /> : null
            } */}
            {
              item.children?.length == 0 || !item.children ?
                <EditOutlined onClick={() => onClick("2", item, parent_data)} /> : null
            }
            {/* {
              item.children?.length == 0 || !item.children ?
                <CloseCircleOutlined onClick={() => onClick("3", item)} /> : null
            } */}
          </span>

        </>
      );
      return (
        <li
          key={onlyKey}
          className={
            !expandIds.includes(item.value) &&
              item.title.indexOf(filterText) === -1
              ? `list-item scale-${onlyKey} hidden`
              : `list-item scale-${onlyKey}`
          }
        >
          <div className="item-con">{context}</div>
          {isFolder ? (
            <ul
              className={`list-ul scale-${item.scale + 1} ${!expandIds.includes(item.value) ? "hidden" : ""
                }`}
            >
              {renderTree(item.children, item)}
            </ul>
          ) : null}
        </li>
      );
    });
  };
  return (
    <div className={`org-tree ${className}`}>
      <div className="tree-box">
        {!loading ? (
          <ul className="list-ul scale-0">{renderTree(newData)}</ul>
        ) : (
          <Loading size="large" />
        )}
      </div>
    </div>
  );
}

export default OrgTree;

import React from 'react'
import { useSelector } from "react-redux";
import { WaterMark } from '@yisa/webui';


function WaterMarkn() {

  const skin = useSelector((state) => {
    return state.comment.skin
  });

  const userInfo = useSelector((state) => {
    return state.user.userInfo
  });

  if (!window.sysConfig.water_mark) {
    return null
  }

  return <WaterMark fontColor={skin == 'light' ? "rgba(0, 0, 0, .15)" : "rgba(255, 255, 255, .15)"} content={[userInfo.name, userInfo.ip]} />
}


export default WaterMarkn


import React, { useEffect, useState } from 'react'
import { useInterval } from 'ahooks'
import { Loading, Image } from '@yisa/webui'
import { ClockOutlined, LocationOutlined, TagOutlined2 } from '@yisa/webui/es/Icon'
import ajax from '@/services'

function Right() {

  const [loading, setLoading] = useState(true)
  const [lastId, setLastId] = useState('')
  const [total, setTotal] = useState(0)
  const [captureData, setCaptureData] = useState([])

  const handleGetData = () => {
    ajax.getOverviewRealTime({
      target: 1,
      last_id: lastId,
    }).then(res => {
      const { day_sum, last_id, list } = res?.data || {}
      setTotal(day_sum || 0)
      setCaptureData(data => [
        ...(Array.isArray(list) ? list : []),
        ...data,
      ].slice(0, 10))
      setLastId(last_id)
    }).catch(err => {
      console.log(err)
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    handleGetData()
  }, [])

  useInterval(() => {
    handleGetData()
  }, sysConfig?.overviewInterval || 5 * 1000)

  return (
    <div className="market-content market-content-right">
      <div className="right-total">
        <h3>当日预警总数</h3>
        <p>{total || 0}</p>
      </div>
      <div className="right-capture">
        <h3>实时预警抓拍</h3>
        <div className="right-capture-list">
          {
            loading && <div className="loading">
              <Loading spinning />
            </div>
          }
          {
            !loading && !captureData.length && <div className="loading">
              暂无抓拍数据
            </div>
          }
          {
            !loading && !!captureData.length && captureData.map((elem, index) => {
              const id = Date.now() + index
              const { pic_url, behavior, capture_time, capture_unit, is_warning } = elem
              const classNames = ['capture-item', is_warning && 'capture-item-warning'].filter(Boolean).join(' ')
              return <div key={id} className={classNames}>
                <div className="capture-item-img">
                  <Image src={pic_url} />
                </div>
                <p>
                  <TagOutlined2 />
                  {behavior || '--'}
                </p>
                <p>
                  <ClockOutlined />
                  {capture_time || '--'}
                </p>
                <p title={capture_unit}>
                  <LocationOutlined />
                  {capture_unit || '--'}
                </p>
              </div>
            })
          }
        </div>
      </div>
    </div>
  )
}

export default Right

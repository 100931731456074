import React from 'react'
import ajax from "@/services";
import './index.scss'

class ErrorBoundary extends React.Component {

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    window.location = './#/'
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (sysConfig && sysConfig.post_error) {
      ajax.postError(sysConfig.post_error, {
        message: '页面信息：' + JSON.stringify(this.props.data) + '，错误信息：' + error.toString()
      }).then(() => { }).catch(() => { })
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className='error-page'>
          <div className='title'>对不起，出错了！</div>
        </div>)
    }

    return this.props.children;
  }
}

export default ErrorBoundary

import React, { useState, useEffect } from "react";
import { Modal, Message } from '@yisa/webui';
import { LeftOutlined, RightOutlined } from '@yisa/webui/es/Icon';

import ImgResize from '../ImgResize'
import "./index.scss";

export default function BigImgSwitch(props) {
  const {
    visible,
    onCancel,
    yisaData,
    currentIndex,
    changeNext = () => { },
    changePrev = () => { },
    modalWidth,
  } = props;

  const [curIndex, setCurIndex] = useState(currentIndex);

  const handlePrevClick = () => {
    if (curIndex == 0) {
      Message.warning("已经是第一张！");
      return;
    }
    setCurIndex(curIndex - 1);
    changePrev(curIndex - 1);
  };

  const handleNextClick = () => {
    if (curIndex == yisaData.length - 1) {
      Message.warning("已经是最后一张！");
      return;
    }
    setCurIndex(curIndex + 1);
    changeNext(curIndex + 1);
  };
  const afterClose = () => {
    setCurIndex(currentIndex);
  }

  useEffect(() => {
    setCurIndex(currentIndex);
  }, [currentIndex]);

  const [btnShow, setBtnShow] = useState(false);

  return (
    <Modal
      title="查看大图"
      className="yisa-modal big-img-switch"
      onCancel={onCancel}
      footer={null}
      visible={visible}
      width={modalWidth}
      afterClose={afterClose}
    >
      <div className="img-main">
        {yisaData.length > 1 && (
          <div className="btn">
            {/* {btnShow && (
                  )} */}
            <>
              <div className={`prev ${!curIndex ? 'disabled' : ''}`} onClick={handlePrevClick}>
                <LeftOutlined />
              </div>
              <div className={`next ${curIndex == yisaData.length - 1 ? 'disabled' : ''}`} onClick={handleNextClick}>
                <RightOutlined />
              </div>
            </>
          </div>
        )}
        {yisaData && yisaData.length > 0 && (
          <ImgResize src={yisaData[curIndex]} />
        )}
      </div>
    </Modal>
  );
}

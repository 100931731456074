import React, { useState, useEffect, useCallback } from "react";
import { TreeSelect } from "@yisa/webui";
import { SearchOutlined } from '@yisa/webui/es/Icon';

import classnames from "classnames";
import "./index.scss";

export default function FormTreeSelect(props) {
  const {
    yisaLabel = "",
    labelPosition = "top",
    onChange = () => { },
    placeholder = "请选择",
    value = [],
    treeData = [],
    maxTagCount = 1,
    disabled = false,
    className = "",
    fieldNames = {},
    popupClassName = "cc-tree-content",
    maxHeight = 400,
    multiple = true,
    showSearch = true,
    allowClear = true,
    disableParent = false,
    isRequired = false,  // 标题前的*
    dropdownMatchSelectWidth = true,
    placement = "bottomLeft"

  } = props;

  const [data, setData] = useState(treeData);

  const handleData = (data) => {
    return data.map((item) => {
      if (item.hasOwnProperty("children")) {
        return {
          disabled: true,
          ...item,
          children: handleData(item.children),
        };
      } else {
        return item;
      }
    });
  };

  useEffect(() => {
    setData(treeData);
  }, [treeData]);

  useEffect(() => {
    if (disableParent && treeData.length) {
      let _data = handleData(treeData);
      setData(_data)
    }
  }, [disableParent, treeData]);
  const filterTreeNode = useCallback((inputText, node) => {
    return node.props.title.toLowerCase().indexOf(inputText.toLowerCase()) > -1;
  }, [])
  return (
    <div
      className={classnames({
        "cc-form-tree": true,
        "form-tree-top": labelPosition == "top",
        "form-tree-left": labelPosition == "left",
        [className]: className != "",
      })}
    >
      {yisaLabel && (
        <div className="cc-form-name">
          {isRequired ? <label className="required">*</label> : null}
          <label>{yisaLabel}</label>
        </div>
      )}
      <div className="cc-form-content">
        <TreeSelect
          style={{ width: "100%" }}
          value={value}
          fieldNames={fieldNames}
          disabled={disabled}
          dropdownStyle={{ maxHeight: maxHeight, overflow: "auto" }}
          treeData={data}
          placeholder={placeholder}
          onChange={onChange}
          treeCheckable={multiple}
          showSearch={showSearch}
          // suffixIcon={showSearch && <SearchOutlined />}
          allowClear={allowClear}
          filterTreeNode={filterTreeNode}
          // treeNodeFilterProp={"label"}
          // showCheckedStrategy={TreeSelect.SHOW_CHILD}
          maxTagCount={maxTagCount}
        // popupClassName={popupClassName}
        // dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        // placement={placement}
        />
      </div>
    </div>
  );
}

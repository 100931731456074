/**
 * Created by ayou on 2017/3/20.
 * Draw polygons based on canvas.
 */
import Event from "./events.js";
import { deepClone } from "./util.js";

function CanvasDraw(config) {
  this.config = deepClone(config, {
    drawType: "polygon", // 当前绘制的图形, polygon-多边形 line-路径 point-点 arrow-箭头
    drawText: "",
    canDraw: true,
    max: 200,
    pointColor: "rgb(218,140,16)",
    pointSize: 5,
    lineColor: "rgb(0, 166, 90)",
    lineSize: 2,
    textColor: "#fff",
    polygonFillColor: "rgba(0,174,217,0.4)",
    polygonLineColor: "rgb(0,174,217)",
    polygonPointlColor: "rgb(0,174,217)",
    polygonPointlSize: 2,
    polygonLineSize: 1,
    polygonActiveFillColor: "rgba(149,255,255,0.4)",
    polygonActiveLineColor: "#95FFFF",
    polygonFrozonFillColor: "rgba(255,88,73,0.4)",
    polygonFrozonLineColor: "#ff5849",
  });
  this.ctx = null;
  this.bgctx = null;
  this.canvas = null;
  this.bgCanvas = null;
  this.wrapper = null;
  this.eventAdded = false;
  // 画板左上角相对于父元素的坐标
  this.lt = {
    x: 0,
    y: 0,
  };
  // 变化前的长和宽
  this.lastWidth = 0;
  this.lastHeight = 0;
  // 图像
  this.img = null;
  this.imgLoaded = false;
  this.imgW = 0;
  this.imgH = 0;
  // 正在绘画的点
  this.points = [];
  // 此次绘画所画图形
  // 多边形, 多条线段, 点
  /**
    {
      type: 'line',
      points: [{x:1, y:2}]
    }
   */
  this.graphs = [];
  // 画布初始时的图形
  // 数据格式跟上面一样
  this.oldGraphs = this.config.oldGraphs || [];
  // if (config.polygons) {
  //   this._polygons = this.clonePolygons(config.polygons)
  // }

  this.init();
}
CanvasDraw.prototype = new Event();
CanvasDraw.prototype.constructor = CanvasDraw;
CanvasDraw.prototype.setDrawType = function (type, key) {
  this.config.drawType = type;
  this.config.currentKey = key;
};
CanvasDraw.prototype.setDrawText = function (text) {
  this.config.drawText = text;
};
CanvasDraw.prototype.setCanDraw = function (canDraw) {
  this.config.canDraw = canDraw;
  if (!this.eventAdded) this.initEventListener();
};
CanvasDraw.prototype.init = function () {
  if (!this.wrapper) {
    const _wrapper = document.getElementById(this.config.id);
    _wrapper.style.position = "relative";
    this.wrapper = _wrapper;
  }
  this.delActDom = document.getElementById(`${this.config.id}_delActDom`);
  this.initBg();
  // 禁止浏览器右键
  this.addHandler(
    document.body,
    "contextmenu",
    this.handleEvent.rightKeyReturn
  );
};

CanvasDraw.prototype.initBg = function (cb, pic = this.config.bgImg) {
  const me = this;
  const image = new Image();
  image.src = pic;
  image.onload = function () {
    let dx = 0;
    let dy = 0;
    me.imgLoaded = true;
    me.img = image;
    me.imgW = image.width;
    me.imgH = image.height;
    // 1 contain方式放置图片
    const wScale = image.width / me.config.width;
    const hScale = image.height / me.config.height;
    const _scale = wScale < hScale ? hScale : wScale;
    let _width = image.width / _scale;
    let _height = image.height / _scale;
    if (wScale < hScale) {
      dx = parseInt((me.config.width - _width) / 2);
    } else {
      dy = parseInt((me.config.height - _height) / 2);
    }

    //  等比缩放图片  宽或高铺满容器
    // let imgScale = image.width / image.height
    // let containerScale = me.config.width / me.config.height
    // let _width = 0
    // let _height = 0

    // let { w, h, top, left } = imgFullContainer(image.width, image.height, me.config.width, me.config.height)
    // _width = w
    // _height = h
    // dx = left
    // dy = top

    // 2 拉伸方式放置图片
    _width = me.config.width;
    _height = me.config.height;
    me.lt.x = dx;
    me.lt.y = dy;
    if (!me.canvas) {
      me.canvas = me.createLayer(dx, dy, _width, _height, 100);
      me.bgCanvas = me.createLayer(dx, dy, _width, _height, 1);
      me.bgctx = me.bgCanvas.getContext("2d");
      me.ctx = me.canvas.getContext("2d");
      me.wrapper.appendChild(me.canvas);
      me.wrapper.appendChild(me.bgCanvas);
      if (me.config.canDraw) {
        me.initEventListener();
      }
      me.bgctx.drawImage(image, 0, 0, _width, _height);
      me.initPolygons();
    } else {
      me.resizeCanvas(me.canvas, dx, dy, _width, _height);
      me.resizeCanvas(me.bgCanvas, dx, dy, _width, _height);
    }
    // 通知listener初始化完成
    me.trigger("afterInit");
    me.lastWidth = _width;
    me.lastHeight = _height;
    me.config.imgOnLoad && me.config.imgOnLoad(true);
    cb && cb();
  };

  //  图片加载失败
  image.onerror = () => {
    this.imgOnErr();
  };
};
//  图片加载失败
CanvasDraw.prototype.imgOnErr = function () {
  if (!this.wrapper) return;

  let img = this.wrapper && this.wrapper.querySelector(".fail_404");

  if (!img) {
    img = document.createElement("img");
    img.className = "fail_404";
    const style = {
      maxWidth: "100%",
      maxHeight: "100%",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      webkitTransform: "translate(-50%, -50%)",
    };
    const keys = Object.keys(style);
    keys.forEach((key) => {
      img.style[key] = style[key];
    });
    //  img.style.height = '100%'
    img.src = "/assets/404.png";
    this.wrapper.appendChild(img);
  }
  this.imgLoaded = false;
  this.config.imgOnLoad && this.config.imgOnLoad(false);
};

CanvasDraw.prototype.initPolygons = function () {
  // 坐标转换，画图
  const len = this.oldGraphs.length;
  for (let i = 0; i < len; i++) {
    const graph = this.oldGraphs[i];
    graph.id = i;
    graph.text = graph.text || "";
    graph.isInitData = true;
    //  没有数据时
    // if (!graph.points.length) {
    //   return
    // }
    const tmpPoints = this.clonePolygon(graph.points);
    for (let j = 0; j < tmpPoints.length; j++) {
      const _p = this.coordinateTransform(tmpPoints[j]);
      tmpPoints[j] = _p;
    }
    // tPoints 是转换后的坐标
    graph.tPoints = tmpPoints;
    const params = [tmpPoints, graph.text];
    if (graph.active) {
      params.push(this.config.polygonActiveLineColor);
      params.push(this.config.polygonActiveFillColor);
    }

    const drawObj = {
      id: i,
      type: graph.type,
      points: tmpPoints,
      text: graph.text || "",
    };
    this.drawGraph.call(this, drawObj);

    // me.trigger('afterRightClick', me.getGraphsData())
    //  this.trigger('initPolygons', this.oldGraphs)
  }
  this.config.initPolygons && this.config.initPolygons(this.oldGraphs);
};
// 画图形
CanvasDraw.prototype.drawGraph = function (graph) {
  const { type, points, text = this.config.drawText } = graph;
  switch (type) {
    case "line":
      this.drawMultiLine(points, text);
      break;
    case "polygon":
      this.drawPolygon(points, text);
      break;
    case "point":
      if (points[0]) {
        const { x } = points[0];
        const { y } = points[0];
        this.drawPoint(x, y);
        this.drawText([{ x, y }], this.ctx, text, "point");
      }
      break;
    case "arrow":
      this.drawArrow(points);
      break;
    default:
      throw Error("no draw function");
  }

  //  this.graphs.push(graph)
};
// 画箭头
CanvasDraw.prototype.drawArrow = function (
  points,
  fillColor = "rgba(32,160,255,0.8)"
) {
  const { ctx } = this;
  // 三角形质心
  const p = {
    x: 0,
    y: 0,
  };
  for (let i = 0; i < points.length; i++) {
    p.x += points[i].x;
    p.y += points[i].y;
  }
  p.x /= 3;
  p.y /= 3;
  // 三角形箭头位置
  const n = 2 / 3;
  // 三角形箭头另外两个点
  const p0 = {
    y: n * (points[2].y - points[0].y) + points[0].y,
    x: n * (points[2].x - points[0].x) + points[0].x,
  };
  const p1 = {
    y: n * (points[2].y - points[1].y) + points[1].y,
    x: n * (points[2].x - points[1].x) + points[1].x,
  };
  ctx.fillStyle = fillColor || this.config.polygonFillColor;
  ctx.lineCap = "miter";
  ctx.beginPath();
  ctx.moveTo(points[0].x, points[0].y);
  ctx.quadraticCurveTo(p.x, p.y, points[2].x, points[2].y);
  ctx.quadraticCurveTo(p.x, p.y, points[1].x, points[1].y);
  ctx.closePath();
  ctx.fill();
  // ctx.fillStyle = rgba(0, 255, ) || this.config.polygonFillColor
  // ctx.lineCap = 'miter'
  // ctx.globalCompositeOperation = 'source-over'
  ctx.beginPath();
  ctx.moveTo(p0.x, p0.y);
  ctx.lineTo(points[2].x, points[2].y);
  ctx.lineTo(p1.x, p1.y);
  ctx.closePath();
  ctx.fill();
};
// 画多边形
CanvasDraw.prototype.drawPolygon = function (
  points,
  text,
  lineColor,
  fillColor
) {
  const len = points.length || 0;
  if (!len || len < 3) {
    console.log("点不够呀！");
    return;
  }
  const { ctx } = this;
  // for (var i = 0; i < len; i++) {
  //   // 点
  //   this.drawPoint(points[i].x, points[i].y)
  // }
  ctx.beginPath();
  ctx.strokeStyle = lineColor || this.config.polygonLineColor;
  ctx.fillStyle = fillColor || this.config.polygonFillColor;
  for (let i = 0; i < len; i++) {
    // 画线
    if (i === 0) {
      ctx.moveTo(points[i].x, points[i].y);
    } else {
      ctx.lineTo(points[i].x, points[i].y);
      ctx.stroke();
    }
  }
  ctx.lineTo(points[0].x, points[0].y);
  ctx.stroke();
  ctx.closePath();
  ctx.fill();

  //  画标签文字
  this.drawText(points, ctx, text, "polygon");
};
//  边界判断
CanvasDraw.prototype.drawTextBoundaryJudgment = function (firstPoint, text) {
  const w = this.config.width;
  const h = this.config.height;

  let { x, y } = firstPoint;
  const measureText = this.ctx.measureText(text);
  const textWidth = measureText.width;

  const xMax = textWidth;
  const yMax = 20;

  //  靠近右边缘 <= 文字宽度
  if (w - x <= xMax) {
    x = w - xMax;
  }
  // else {
  //   x += yMax
  // }

  //  高度 <= yMax
  if (h - y <= yMax) {
    y -= yMax;
  }
  //  高度 <= yMax
  else if (y <= yMax) {
    y += yMax;
  } else {
    y -= 5;
  }

  return { x, y };
};
//  画标签文字
CanvasDraw.prototype.drawText = function (points, ctx, text, type) {
  const len = points.length;
  if ((!text || !len) && text !== 0) return;

  let cx = 0;
  let cy = 0;

  for (let i = 0; i < len; i++) {
    cx += points[i].x;
    cy += points[i].y;
  }
  cx /= len;
  cy /= len;
  let firstPoint = points[0];
  firstPoint = this.drawTextBoundaryJudgment(firstPoint, text);
  const { x, y } = firstPoint;
  const textAlign = "left";

  cx = x;
  cy = y;

  ctx.fillStyle = this.config.textColor;
  ctx.font = "normal 14px Microsoft YaHei";
  ctx.textAlign = textAlign;
  ctx.fillText(text, cx, cy);
};
//  更新标签数据
CanvasDraw.prototype.updateDrawDataText = function (text, id, isInitData) {
  //  修改对应数据添加text
  if (id || id === 0) {
    if (isInitData) {
      this.oldGraphs[id].text = text;
    } else {
      const index = id - this.oldGraphs.length;
      this.graphs[index].text = text;
    }
  }
};
// 克隆一个多边形的数据
CanvasDraw.prototype.clonePolygon = function (polygon) {
  const pointNum = polygon.length;
  const tmpPoints = [];
  for (let i = 0; i < pointNum; i++) {
    const tmpPoint = {};
    tmpPoint.x = polygon[i].x;
    tmpPoint.y = polygon[i].y;
    tmpPoints.push(tmpPoint);
  }
  return tmpPoints;
};
// 克隆传递进来的多边形数据，防止修改到外面传递进来的数据
CanvasDraw.prototype.clonePolygons = function (polygons) {
  const len = polygons.length;
  let tmpPoints = [];
  const tmpPolygons = [];
  for (let i = 0; i < len; i++) {
    tmpPoints = this.clonePolygon(polygons[i].points);
    tmpPolygons.push({
      text: polygons[i].text,
      active: polygons[i].active,
      points: tmpPoints,
    });
  }
  return tmpPolygons;
};
CanvasDraw.prototype.createLayer = function (x, y, w, h, zIndex) {
  const canvas = document.createElement("canvas");
  this.setLayerPos(canvas, x, y, w, h, zIndex);
  return canvas;
};
//  设置画布位置
CanvasDraw.prototype.setLayerPos = (canvas, x, y, w, h, zIndex) => {
  canvas.width = w;
  canvas.height = h;
  canvas.style.position = "absolute";
  // canvas.style.top = y + 'px'
  // canvas.style.left = x + 'px'
  canvas.style.top = 0;
  canvas.style.left = 0;
  canvas.style.zIndex = zIndex;
};
CanvasDraw.prototype.resizeCanvas = function (canvas, x, y, w, h) {
  canvas.width = w;
  canvas.height = h;
  canvas.style.top = `${y}px`;
  canvas.style.left = `${x}px`;
};
CanvasDraw.prototype.resizeDraw = function () {
  const me = this;

  function _resizePoint(point) {
    point.x *= me.canvas.width / me.lastWidth;
    point.y *= me.canvas.height / me.lastHeight;
  }
  const pointsNum = this.points.length;
  for (let i = 0; i < pointsNum; i++) {
    _resizePoint(this.points[i]);
  }
  const polygonsNum = this.graphs.length;
  for (let i = 0; i < polygonsNum; i++) {
    const pNum = this.graphs[i].points.length;
    for (let j = 0; j < pNum; j++) {
      _resizePoint(this.graphs[i].points[j]);
    }
  }
  const oldPolygonsNum = this.oldGraphs.length;
  for (let i = 0; i < oldPolygonsNum; i++) {
    const graph = this.oldGraphs[i];
    const pNum = graph.points.length;
    for (let j = 0; j < pNum; j++) {
      graph.tPoints[j].x = graph.points[j].x * me.canvas.width;
      graph.tPoints[j].y = graph.points[j].y * me.canvas.height;
    }
  }
};
CanvasDraw.prototype.coordinateTransform = function (point) {
  return {
    x: parseFloat(point.x) * this.canvas.width,
    y: parseFloat(point.y) * this.canvas.height,
  };
};
CanvasDraw.prototype.addPolygons = function (polygons) {
  const len = polygons.length;
  const tmpPolygons = [];
  for (let i = 0; i < len; i++) {
    const pointNum = polygons[i].points.length;
    const tmpPoints = [];
    for (let j = 0; j < pointNum; j++) {
      const tmpPoint = {};
      tmpPoint.x = polygons[i].points[j].x;
      tmpPoint.y = polygons[i].points[j].y;
      const _p = this.coordinateTransform(tmpPoint);
      tmpPoints.push(_p);
    }
    tmpPolygons.push({
      text: polygons[i].text,
      tPoints: tmpPoints,
      points: polygons[i].points,
    });
  }
  this.oldGraphs = this.oldGraphs.concat(tmpPolygons);
  this.reDraw();
};
CanvasDraw.prototype.drawPoint = function (x, y, color, size) {
  const { ctx } = this;
  ctx.beginPath();
  ctx.fillStyle = color || this.config.pointColor;
  ctx.arc(x, y, size || this.config.pointSize, 0, 2 * Math.PI);
  ctx.fill();
};
CanvasDraw.prototype.drawLine = function (x1, y1, x2, y2, color, size) {
  const { ctx } = this;
  ctx.beginPath();
  ctx.strokeStyle = color || this.config.lineColor;
  ctx.lineWidth = size || this.config.lineSize;
  ctx.moveTo(x1, y1);
  ctx.lineTo(x2, y2);
  ctx.stroke();
};
CanvasDraw.prototype.drawMultiLine = function (points, text) {
  const pLen = points.length;

  if (!pLen) return;

  if (pLen > 0) {
    for (let i = 0; i < pLen - 1; i++) {
      // 点
      this.drawPoint(points[i].x, points[i].y, this.config.lineColor);
      // 线
      this.drawLine(points[i].x, points[i].y, points[i + 1].x, points[i + 1].y);
    }
    // 最后那个点
    this.drawPoint(
      points[pLen - 1].x,
      points[pLen - 1].y,
      this.config.lineColor
    );

    this.drawText(points, this.ctx, text, "line");
  }
};
CanvasDraw.prototype.reDraw = function (isReDrawBgImg) {
  //  var me = this
  // 清空画布
  this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  // 画背景
  if (isReDrawBgImg) {
    this.bgctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.bgctx.drawImage(this.img, 0, 0, this.canvas.width, this.canvas.height);
  }
  // 当前正在绘制的图
  this.drawMultiLine(this.points);
  // 画多边形
  const oldLen = this.oldGraphs.length;
  if (oldLen) {
    for (let i = 0; i < oldLen; i++) {
      const oldItem = this.oldGraphs[i];
      this.drawGraph({
        id: i,
        text: oldItem.text || "",
        type: oldItem.type,
        points: oldItem.tPoints,
      });
    }
  }

  // 本次画的图形
  const nowLen = this.graphs.length;
  if (!nowLen) {
    return;
  }
  for (let i = 0; i < nowLen; i++) {
    this.drawGraph(this.graphs[i]);
  }
};
CanvasDraw.prototype.isIn = function (x, y) {
  const me = this;
  if (x > 0 && me.canvas.width > x && y > 0 && me.canvas.height > y) {
    return true;
  }
  return false;
};
CanvasDraw.prototype.afterRightClick = function () {
  if (this.graphs.length >= this.config.max) return;
  const graph = {
    points: this.points,
    text: "",
  };
  switch (this.config.drawType) {
    case "line":
      graph.type = "line";
      if (this.points.length < 2) {
        return;
      }
      break;
    case "polygon":
      graph.type = "polygon";
      if (this.points.length < 3) {
        return;
      }
      break;
    default:
      throw Error("no draw type");
  }
  const idIndex = this.graphs.length + this.oldGraphs.length;

  graph.id = idIndex;
  graph.text = this.config.drawText || "";
  graph.key = this.config.key;
  this.graphs.push(graph);
  this.points = [];
  this.reDraw();
  // this.trigger('afterRightClick', this.getGraphsData())
  this.afterDrawGraph(this.graphs);
};

CanvasDraw.prototype.renderDelIcon = function () {
  this.clearDelIcon();
  const findPoint = (item) => {
    if (item.tPoints && item.tPoints.length > 0) {
      return item.tPoints[0];
    }
    if (item.points && item.points.length > 0) {
      return item.points[0];
    }
    return { x: 0, y: 0 };
  };

  const addDelSpan = (item) => {
    const point = findPoint(item);
    const span = document.createElement("span");
    span.innerText = "x";
    span.style.position = "absolute";
    span.style.left = `${point.x}px`;
    span.style.top = `${point.y}px`;
    span.style.zIndex = "100000";
    span.style.cursor = "pointer";
    span.style.color = "white";
    span.style.fontSize = "20px";
    span.onclick = () => {
      this.removeGraph(item);
    };
    this.delActDom.appendChild(span);
  };

  const oldLen = this.oldGraphs.length;
  if (oldLen) {
    for (let i = 0; i < oldLen; i++) {
      addDelSpan(this.oldGraphs[i]);
    }
  }

  const nowLen = this.graphs.length;
  if (!nowLen) {
    return;
  }
  for (let i = 0; i < nowLen; i++) {
    addDelSpan(this.graphs[i]);
  }
};

CanvasDraw.prototype.clearDelIcon = function () {
  this.delActDom.innerHTML = "";
};

CanvasDraw.prototype.removeGraph = function (point) {
  const delGraph = (array, index) => {
    array.splice(index, 1);
    this.points = [];
    this.reDraw();
    this.config.drawEnd(this.oldGraphs.concat(this.graphs));
    // this.clearDelIcon()
    this.renderDelIcon();
    this.config.afterIconDelEvent && this.config.afterIconDelEvent();
    this.config.canDraw = false;
  };

  let keyIndex = this.graphs.findIndex((item) => item === point);

  if (keyIndex !== -1) {
    delGraph(this.graphs, keyIndex);
  } else {
    keyIndex = this.oldGraphs.findIndex((item) => item === point);
    if (keyIndex !== -1) delGraph(this.oldGraphs, keyIndex);
  }
};

/**
 *
 * @param {*} key 可为 key[] | key
 */
CanvasDraw.prototype.removeKeysGraph = function (key) {
  if (Array.isArray(key)) {
    // 删除多个key
    this.graphs = this.graphs.filter((item) => !key.includes(item.key));
    this.oldGraphs = this.oldGraphs.filter((item) => !key.includes(item.key));
  } else {
    // 删除单个key
    this.graphs = this.graphs.filter((item) => item.key !== key);
    this.oldGraphs = this.oldGraphs.filter((item) => item.key !== key);
  }

  this.points = [];
  this.reDraw();
  this.config.drawEnd(this.oldGraphs.concat(this.graphs));
  this.clearDelIcon();
};

CanvasDraw.prototype.afterDrawGraph = function (graph) {
  // 绘制后给予key值
  graph[graph.length - 1].key = this.config.currentKey;
  this.config.drawEnd(graph);
};

//  画布事件绑定
CanvasDraw.prototype.handleEvent = {
  //  鼠标右键
  rightKeyReturn(e) {
    //  e.returnValue = false
    e.preventDefault();
  },
  //  canvas鼠标点击
  canvasMouseDown(e) {
    if (!this.config.canDraw) return;
    if (this.graphs.length === this.config.max) return;
    // 鼠标左键
    if (e.button === 0) {
      const x = e.offsetX;
      const y = e.offsetY;
      if (
        this.config.drawType === "point" &&
        this.graphs.length < this.config.max
      ) {
        const graph = {
          points: [
            {
              x,
              y,
            },
          ],
          type: "point",
          text: this.config.drawText || "",
          id: this.graphs.length + this.oldGraphs.length,
        };
        this.graphs.push(graph);
        this.reDraw();
        this.afterDrawGraph(this.graphs);
        return;
      }
      if (!this.isIn(x, y)) return;
      this.points.push({
        x,
        y,
      });
      if (this.config.drawType === "arrow" && this.points.length === 3) {
        const graph = {
          points: this.points,
          type: "arrow",
          id: this.graphs.length + this.oldGraphs.length,
        };
        this.graphs.push(graph);
        this.points = [];
        this.reDraw();
        this.afterDrawGraph(this.graphs);
        return;
      }
      this.drawPoint(x, y);
    }
    // 鼠标右键
    if (e.button === 2) {
      if (this.config.drawType === "point" || this.config.drawType === "arrow")
        return;
      if (this.points.length <= 0) return;
      this.afterRightClick();
    }
  },
  //  canvas鼠标移动
  canvasMouseMove(e) {
    if (!this.config.canDraw) return;
    if (this.config.drawType === "point") return;
    if (this.graphs.length === this.config.max) return;
    if (this.points.length === 0) {
      return;
    }
    const x = e.offsetX;
    const y = e.offsetY;
    const lastX = this.points[this.points.length - 1].x;
    const lastY = this.points[this.points.length - 1].y;
    this.reDraw();
    this.drawLine(lastX, lastY, x, y);
  },
  keyup(e) {
    if (e.keyCode === 27) {
      if (this.points.length > 0) {
        this.points.pop();
      } else {
        this.graphs.pop();
      }
      this.reDraw();
    }
  },
};

CanvasDraw.prototype.initEventListener = function () {
  this.eventAdded = true;
  //  画布canvas 鼠标点击
  this.addHandler(
    this.canvas,
    "mousedown",
    this.handleEvent.canvasMouseDown.bind(this)
  );
  //  画布canvas 鼠标移动
  this.addHandler(
    this.canvas,
    "mousemove",
    this.handleEvent.canvasMouseMove.bind(this)
  );
  this.addHandler(window, "keyup", this.handleEvent.keyup.bind(this));
};
CanvasDraw.prototype.addHandler = function (ele, type, handler) {
  if (ele.addEventListener) {
    ele.addEventListener(type, handler, false);
  } else if (ele.attachEvent) {
    ele.attachEvent(`on${type}`, handler);
  } else {
    ele[`on${type}`] = handler;
  }
};
//  事件解绑
CanvasDraw.prototype.removeHandler = function (ele, type, handler) {
  if (ele.removeEventListener) {
    ele.removeEventListener(type, handler);
  } else if (ele.detachEvent) {
    ele.detachEvent(`on${type}`, handler);
  } else {
    ele[`on${type}`] = null;
  }
};
// 得到相对于画板归一化的坐标
CanvasDraw.prototype.getGraphsData = function () {
  //  获取数据不保留已经画的图形
  // var len = this.graphs.length
  // for (var i = 0; i < len; i++) {
  //   var graph = this.graphs[i]
  //   var pN = graph.points.length
  //   for (var j = 0; j < pN; j++) {
  //     graph.points[j].x /= this.canvas.width
  //     graph.points[j].y /= this.canvas.height
  //   }
  // }
  // let obj = deepClone(this.graphs)
  // this.graphs = []
  // return obj

  const arr = deepClone(this.graphs);
  const len = arr.length;
  for (let i = 0; i < len; i++) {
    const graph = arr[i];
    const pN = graph.points.length;
    graph.tPoints = deepClone(graph.points);
    for (let j = 0; j < pN; j++) {
      graph.points[j].x /= this.canvas.width;
      graph.points[j].y /= this.canvas.height;
    }
  }

  if (this.oldGraphs.length) {
    return this.oldGraphs.concat(arr);
  }

  return arr;
};
// 重置本次绘画
CanvasDraw.prototype.reset = function () {
  this.graphs = [];
  this.reDraw();
};
// 清空所有多边形
CanvasDraw.prototype.clean = function (isCleanAll, imgUrl) {
  this.graphs = [];
  this.oldGraphs = [];
  this.ctx && this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  this.setCanDraw(true);

  //  清空全部并重画背景图以及旧的点线框
  if (isCleanAll) {
    this.bgctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    const img = new Image();
    img.src = imgUrl;
    img.onload = () => {
      //  等比适配容器放大图片
      // let { w, h, top, left } = imgFullContainer(img.width, img.height, this.config.width, this.config.height)
      // this.resizeCanvas(this.canvas, left, top, w, h)
      // this.resizeCanvas(this.bgCanvas, left, top, w, h)

      this.bgctx.drawImage(img, 0, 0, this.canvas.width, this.canvas.height);
      this.config.imgOnLoad && this.config.imgOnLoad(true);
      this.imgLoaded = true;
    };
    img.onerror = () => {
      this.imgOnErr();
    };
  }
};
CanvasDraw.prototype.delete = function (cb) {
  const _parentElement = this.canvas.parentNode;
  if (_parentElement) {
    _parentElement.removeChild(this.canvas);
    _parentElement.removeChild(this.bgCanvas);
    cb && cb();

    //  清空对象
    this.canvas = null;
    this.wrapper = null;
  }
};
//  销毁
CanvasDraw.prototype.dispose = function (cb) {
  if (!this.canvas) return;
  //  事件解绑
  this.removeEventListener();
  //  清空dom
  this.delete();
};
//  事件解绑
CanvasDraw.prototype.removeEventListener = function () {
  //  this.removeHandler(document.body, 'contextmenu', this.handleEvent.rightKeyReturn)

  this.removeHandler(
    this.canvas,
    "mousedown",
    this.handleEvent.canvasMouseDown
  );
  //  画布canvas 鼠标移动
  this.removeHandler(
    this.canvas,
    "mousemove",
    this.handleEvent.canvasMouseMove
  );
  this.removeHandler(window, "keyup", this.handleEvent.keyup);
  this.config = null;
};
CanvasDraw.prototype.resize = function (width, height, cb) {
  const me = this;
  me.config.width = width;
  me.config.height = height;
  me.init(function () {
    me.resizeDraw();
    me.reDraw(true);
    typeof cb === "function" && cb();
  });
};
export default CanvasDraw;

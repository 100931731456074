import React from "react";
import { Loading } from '@yisa/webui';
import noDataImg from "@/assets/images/no-data.png";
import "./index.scss";

/**
 *
 * @param {boolean} hasLoad 是否有加载动画
 * @param {boolean} loading
 * @param {*} resultData 数据
 */

export default function ResultFlow(props) {
  const {
    hasLoad = true,
    loading = true,
    resultData = [],
    message = "",
    img = require('@/assets/images/no-data.png'),
    fontStyle = {
      fontSize: '20px',
      fontFamily: 'Microsoft YaHei, Microsoft YaHei - Regular',
      fontWeight: 400,
      color: '#dde5ef',
    }
  } = props;
  return (
    <div className="cc-result-flow">
      {hasLoad && loading ? (
        <Loading spinning={true} />
      ) : (
        <main className="cc-result-flow-main">
          {resultData.length ||
            (JSON.stringify(resultData) !== "{}" &&
              Object.prototype.toString.call(resultData) ===
              "[object Object]") ? (
            props.children
          ) : (
            <div className="no-data">
              <img src={img} alt="" draggable="false" />
              <p style={fontStyle}>{message}</p>
            </div>
          )}
        </main>
      )}
    </div>
  );
}

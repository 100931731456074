import React, { useState, useEffect, useRef } from 'react';
import { Table, Button, Pagination, Message, TreeSelect, Modal, Image } from '@yisa/webui';
import ajax from '@/services';
import './index.scss'
import { character } from "@/utils";
export default function ViewModal(props) {
  const {
    thirdId = '',
    setThirdId = () => { }
  } = props;


  const baseThirdForm = {
    page: 1,
    page_size: character.pageSizeOptions[0],
  }
  const [thirdForm, setThirdForm] = useState(baseThirdForm);
  const [thirdData, setThirdData] = useState([]);
  const [thirdVisible, setThirdVisible] = useState(false);
  const thirdColumns = [
    {
      title: "单位",
      dataIndex: "org_name",
      align: "center",
    },
    {
      title: "人员",
      dataIndex: "user_name",
      align: "center",
    },
    {
      title: "查看时间",
      dataIndex: "last_time",
      align: "center",
    },
    {
      title: "查看次数",
      dataIndex: "total_count",
      align: "center",
    },
  ]
  const getThirdData = (params) => {
    ajax.getReadLogList({
      page: 1,
      page_size: character.pageSizeOptions[0],
      ...params,
      event_id: thirdId
    }).then(res => {
      if (res.status === 20000) {
        setThirdData(res.data);
        setThirdVisible(true);
      } else {
        Message.error(res.message)
      }
    }).catch(err => {
      let temp = new Array(17).fill({
        target_id: '111',
        org_name: '11'
      })
      setThirdData({
        total: 200,
        list: temp
      })
      setThirdVisible(true);
      console.log(err)
    })
  }

  useEffect(() => {
    if (thirdId) {
      getThirdData();
    }
    return () => {
      setThirdForm(baseThirdForm);
      setThirdData([]);
    }
  }, [thirdId])
  return (
    <>
      <Modal
        title={'已读详情'}
        className='cylinder-detail-modal cylinder-view-modal'
        visible={thirdVisible}
        width={790}
        maskClosable={false}
        hideCancel={true}
        onOk={() => {
          setThirdForm(baseThirdForm);
          setThirdVisible(false);
          setThirdId('');
        }}
        onCancel={() => {
          setThirdForm(baseThirdForm);
          setThirdVisible(false);
          setThirdId('');
        }}
      >
        <Table
          className="table-warp cylinder-detail-modal-table"
          columns={thirdColumns}
          data={thirdData?.list ?? []}
          rowKey="id"
          bordered
          scroll={{
            y: 400
          }}
          pagination={false}
        />
        {
          thirdData.total && thirdData.total > character.pageSizeOptions[0] ?
            <Pagination
              className="pagination-common"
              showQuickJumper
              showSizeChanger
              size='default'
              position={['bottomCenter']}
              current={thirdForm.page}
              total={thirdData?.total ?? 0}
              pageSize={thirdForm.page_size}
              onChange={(page, pageSize) => {
                let obj = {};
                if (pageSize !== thirdForm.page_size) {
                  obj.page = 1
                  obj.page_size = pageSize
                } else {
                  obj.page = page
                }
                let newParams = Object.assign({}, thirdForm, obj);
                setThirdForm(newParams);
                getThirdData(newParams);
              }}
              pageSizeOptions={character.pageSizeOptions}
            /> : <></>
        }
      </Modal>
    </>
  )
}


import React, { useState, useEffect, useRef } from "react";
import { Modal, Message, Input, Button } from '@yisa/webui';
import { pwdRegStrategy, pwdString, aesUtil, setID, removeToken } from '@/utils'
import ajax from "@/services";
import { useSelector } from 'react-redux';
import "./index.scss";

/**
 * @description 修改密码弹框
 * @param {Boolean}   visible 弹框状态
 * @param {String}    className 弹窗类名 （modal-light固定亮色模式）
 * @param {Function}  onCancel 取消按钮方法
 */
function ModalEditPwd(props) {
  const { className = "modal-light", visible, onCancel } = props;
  const id = useSelector((state) => {
    return state.user.userInfo.id;
  });

  const defaultData = {
    id,
    old: "",
    newp: "",
    newp2: "",
    password_security_strength: "",
  };
  // 提交字段
  const [formData, setFormData] = useState(defaultData);
  // 密码策略
  const [pwdRole, setPwdRole] = useState({
    rules_type: '1', // 规则类型
    min_length: 8, // 最小长度
  });
  // 密码等级 white-无，red-低，yellow-中，green-高
  // const [markColor, setMarkColor] = useState(["white", "white", "white"]);
  // 提交状态
  const [submitting, setSubmitting] = useState(false);
  const isInstance = useRef(null);
  const serverRsaPubKey = useRef("");

  useEffect(() => {
    if (visible) {
      // 重置页面栏位
      setFormData(defaultData);
      // setMarkColor(["white", "white", "white"]);
      // 获取密码加密公钥
      // getPubKey();
    }
  }, [visible]);

  // 获取公钥
  // const getPubKey = () => {
  //   ajax
  //     .getLoadPbk()
  //     .then(
  //       (res) => {
  //         if (res.status === 0) {
  //           // isInstance.current = createIS();
  //           serverRsaPubKey.current = res.key;
  //           isInstance.current.rsaUtil.getKeyPair();
  //         } else {
  //           console.log(res.message);
  //         }
  //       },
  //       (err) => {
  //         console.log(err);
  //       }
  //     )
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  // 点击按钮提交
  const handleOk = () => {
    if (!formData.old) {
      Message.error("请输入账户原密码");
      return false;
    }
    if (!formData.newp) {
      Message.error("请输入账户新密码");
      return false;
    }
    if (
      formData.newp.length < parseInt(pwdRole.min_length) ||
      !pwdRegStrategy(formData.newp, pwdRole.rules_type)
    ) {
      Message.error("账户新密码格式不符合系统要求，请重新填写");
      return false;
    }
    if (!formData.newp2) {
      Message.error("请在确认新密码输入框中再填写一次新密码");
      return false;
    }
    if (formData.newp != formData.newp2) {
      Message.error("新密码和确认新密码不相同");
      return false;
    }
    let obj = {
      id: formData.id,
      new_password: aesUtil.encrypt(formData.newp),
      old_password: aesUtil.encrypt(formData.old)
    }
    setSubmitting(true);
    ajax.doEditPwd(obj).then(
      (res) => {
        setSubmitting(false);
        if (res.status === 20000) {
          Message.success("密码修改成功，请重新登录");
          onCancel()
          // 退出登录
          removeToken()
          window.user_id = undefined
          setID(undefined)
          window.location.replace(sysConfig.login_url)
        } else {
          Message.error(res.message);
        }
      },
      (err) => {
        setSubmitting(false);
        Message.error("服务器连接失败！");
        console.log(err);
      }
    );
  };

  // 原密码修改
  const handleChangeOld = (e) => {
    setFormData({
      ...formData,
      ...{ old: e.target.value.replace(/[\s]*/g, "") },
    });
  };

  // 新密码修改，只支持输入大小写字母、数字
  const handleChangeNew = (e) => {
    let val = pwdString(e.target.value);
    console.log(val);
    setFormData({ ...formData, ...{ newp: val } });
    // if (val.length) {
    //   if (val.length >= parseInt(pwdRole.min_length)) {
    //     if (utils.pwdTest(val, pwdRole.rules_type)) {
    //       // 0-包含三种以上
    //       setMarkColor(["green", "green", "green"]);
    //     } else {
    //       setMarkColor(["yellow", "yellow", "white"]);
    //     }
    //   } else {
    //     setMarkColor(["red", "white", "white"]);
    //   }
    // } else {
    //   setMarkColor(["white", "white", "white"]);
    // }
  };
  // 确认新密码修改
  const handleChangeNew2 = (e) => {
    setFormData({
      ...formData,
      ...{ newp2: pwdString(e.target.value) },
    });
  };

  return (
    <Modal
      wrapClassName={`modal-edit-pwd ${className}`}
      visible={visible}
      title="修改密码"
      width={480}
      onOk={handleOk}
      onCancel={onCancel}
      keyboard={false}
      maskClosable={false}
      footer={
        <Button
          type="primary"
          key="submit"
          onClick={handleOk}
          loading={submitting}
        >
          保存
        </Button>
      }
    >
      <div>
        <div className="label-text">
          <i>*</i>原密码
        </div>
        <div className="input-box">
          <Input
            type="password"
            placeholder="请输入"
            onChange={handleChangeOld}
            value={formData.old}
            autoComplete="off"
            maxLength="50"
          />
        </div>
      </div>
      <div>
        <div className="label-text">
          <i>*</i>新密码
        </div>
        <div className="input-box">
          <Input
            type="password"
            placeholder="请输入"
            onChange={handleChangeNew}
            value={formData.newp}
            autoComplete="off"
            maxLength="50"
          />
          <div className="tip-text">
            {`必须包含大小写字母+数字，长度
            ${parseInt(pwdRole.min_length) < 15 ? pwdRole.min_length + "~" : ""}15位`}
          </div>
          {/* <div className="mark-text">
            <span>安全强度</span>
            {markColor.map((item, index) => {
              return <span key={item + index} className={item}></span>;
            })}
            {markColor[0] === "red"
              ? "低"
              : markColor[0] === "yellow"
                ? "中"
                : markColor[0] === "green"
                  ? "高"
                  : ""}
          </div> */}
        </div>
      </div>
      <div>
        <div className="label-text">
          <i>*</i>确认新密码
        </div>
        <div className="input-box">
          <Input
            type="password"
            placeholder="请输入"
            onChange={handleChangeNew2}
            value={formData.newp2}
            autoComplete="off"
            maxLength="50"
          />
        </div>
      </div>
    </Modal>
  );
};

export default ModalEditPwd;

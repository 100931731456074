import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter } from "react-router-dom";
import { Provider } from 'react-redux'
import store from '@/store/store'
import '@yisa/yisa-map/dist/yisa-map.mini.css'

import App from './app';
import './assets/css/index.scss'


ReactDOM.createRoot(
  document.getElementById('root')
).render(
  <Provider store={store}>
    <HashRouter>
      <App />
    </HashRouter>
  </Provider>
);

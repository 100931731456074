/* eslint-disable */
import { deepClone } from "./util.js";
import { Button, Loading } from "@yisa/webui";
import { useDebounceFn, useMap } from "ahooks";
import {
  useEffect,
  useReducer,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import CanvasDraw from "./point_line_polygon.js";
import "./style.scss";
import noData from "./assets/roi_nodata.png";

const initialArg = {
  btns: {
    polygon: "border",
    line: "minus",
    point: "",
  },
  drawData: [],
  initBtns: [],
  drawType: "",
  imgIsLoaded: false,
  isCanDraw: true,
  id: "",
  pic: "",
  data: [],
  key: "",
  isLoading: false,
  selectedKey: "",
  selectedName: "",
  isErrorPic: false,
};

const reducer = (payload, action) => {
  const { type, ...others } = action;
  switch (type) {
    case "CHANGE_STATE":
      return {
        ...payload,
        ...others,
      };
    default:
      return { ...payload };
  }
};

function PointLinePolygon(props, ref) {
  const { taskId } = props
  const [_data, dispatch] = useReducer(reducer, initialArg);
  const { imgIsLoaded, id, pic, isLoading } = _data;
  const [map, { set, setAll, remove, reset, get }] = useMap([]);
  const idsRef = useRef({});

  useEffect(() => {
    initDraw();
  }, []);

  useEffect(() => {
    if (_data.id) {
      onInit(_data.pic);
    }
  }, [_data.id]);

  const { run: onResize } = useDebounceFn(
    () => {
      idsRef.current[_data.id]?.dispose();
      dispatch({ type: "CHANGE_STATE", ...initialArg });
      setTimeout(() => {
        initDraw();
      }, 0);
    },
    {
      wait: 500,
    }
  );

  useEffect(() => {
    window.addEventListener("resize", onResize, false);
    return () => window.removeEventListener("resize", onResize, false);
  }, []);

  useEffect(() => {
    if (props.mainKey) {
      idsRef.current[_data.id]?.dispose();
      dispatch({ type: "CHANGE_STATE", ...initialArg });
      setTimeout(() => {
        initDraw();
      }, 0);
    }
  }, [props.mainKey]);

  const initDraw = (pic = "") => {
    const { data, mainKey = "key" } = props;
    const { roi, picPath, initBtns } = deepClone(data);

    const idStr = window.btoa(encodeURI(mainKey.toString())).replace(/=/g, "");
    const id = `canvasDraw_${idStr}`;
    idsRef.current[id] = null;

    dispatch({
      type: "CHANGE_STATE",
      id,
      pic: pic || picPath,
      initBtns,
      data: roi,
      drawData: roi,
      key: mainKey,
    });
  };

  const onInit = (pic) => {
    if (!pic) return;
    if (get(pic)) {
      const list = get(pic);
      initPointLinePolygon(list[0], list[1], pic);
    } else {
      const image = new Image();
      image.src = pic;
      image.onload = () => {
        set(pic, [image.width, image.height]);
        initPointLinePolygon(image.width, image.height, pic);
      };
    }
  };

  const initIsCanDraw = () => {
    return false;
  };

  const initPointLinePolygon = (w, h, pic) => {
    const box = document.querySelector(`#polygon_content`);
    if (pic && box.offsetWidth === 0) {
      setTimeout(() => {
        initPointLinePolygon(w, h, pic);
      }, 100);
      return;
    }

    const bili = h / w;
    const boxbili = box.offsetHeight / box.offsetWidth;
    let width = box.offsetWidth;
    let height = box.offsetHeight;
    if (bili > boxbili) {
      width = height / bili;
      box.style.paddingLeft = `${(box.offsetWidth - width) / 2}px`;
      box.style.paddingTop = `0px`;
    } else {
      height = width * bili;
      box.style.paddingTop = `${(box.offsetHeight - height) / 2}px`;
      box.style.paddingLeft = `0px`;
    }

    const { initBtns = [], drawType, data, id } = _data;
    const points = deepClone(data);

    const canDraw = initIsCanDraw();
    const opt = {
      id,
      drawType,
      bgImg: pic,
      oldGraphs: points,
      canDraw,
      width,
      height,
      left: 20,
      drawEnd: (graphs) => {
        drawEnd();
      },
      imgOnLoad: (isloaded) => {
        dispatch({ type: "CHANGE_STATE", imgIsLoaded: isloaded });
        if (!isloaded) {
          const _id = idsRef.current[id];
          dispatch({ type: "CHANGE_STATE", isErrorPic: true });
          _id.dispose();
        }
      },
      initPolygons: (oldGraphs) => {
        props.initPolygonCb && props.initPolygonCb(oldGraphs);
      },
      afterIconDelEvent: () => {
        dispatch({ type: "CHANGE_STATE", selectedKey: "" });
      },
    };

    idsRef.current[id] = new CanvasDraw(opt);
  };

  const clean = (isCleanAll, img) => {
    const { id, selectedKey } = _data;
    const _id = idsRef.current[id];
    if (!_id) return;

    _id.clearDelIcon();

    if (isCleanAll) {
      _id.clean(isCleanAll, img);
    } else {
      _id.clean();
    }

    if (selectedKey === "") _id.setCanDraw(false);
  };

  const getDrawData = () => {
    const { id } = _data;
    const _id = idsRef.current[id];
    if (!_id) return [];

    if (_id) {
      const data = _id.getGraphsData();
      return data;
    }
  };

  const setCanDraw = (boolean) => {
    const { id } = _data;
    const _id = idsRef.current[id];
    if (!_id) return;

    _id.setCanDraw(boolean);
  };

  const setDrawType = (type, key, name) => {
    const { id } = _data;
    const _id = idsRef.current[id];
    if (!_id) return;
    dispatch({
      type: "CHANGE_STATE",
      drawType: type,
      selectedKey: key,
      selectedName: name,
    });

    _id.setDrawType(type, key);
    _id.setDrawText(name);
    _id.clearDelIcon();
    setCanDraw(true);
  };

  const renderBtnColumn = (type, name) => {
    const { initBtns = [], selectedKey, pic, imgIsLoaded } = _data;

    if (initBtns.some((item) => item.type === type)) {
      return (
        <div
          className={`type_column ${initBtns.length === 1 ? "type_column1" : ""
            }`}
        >
          {initBtns.map((item, i) => {
            if (item.type === type) {
              return (
                <Button
                  key={i}
                  disabled={!pic || !imgIsLoaded}
                  type={selectedKey === item.key ? "primary" : "normal"}
                  onClick={() => {
                    setDrawType(item.type, item.key, item.name);
                  }}
                >
                  {item.name}
                </Button>
              );
            }
          })}
        </div>
      );
    }
  };

  const renderDelIcon = () => {
    const { id } = _data;
    const _id = idsRef.current[id];
    if (!_id) return;
    _id.renderDelIcon();
  };

  const drawEnd = () => {
    const drawData = getDrawData();
    props.drawEnd({
      drawData,
    });

    dispatch({ type: "CHANGE_STATE", selectedKey: "", drawData });
    setCanDraw(false);
  };

  const removeGraphFromKey = () => {
    const { id, initBtns = [] } = _data;

    const _id = idsRef.current[id];
    if (!_id) return;

    _id.removeKeysGraph(initBtns.map((item) => item.key));
  };

  useImperativeHandle(ref, () => ({ removeGraphFromKey, clean }));

  return (
    <div className="point_line_polygon_container">
      <Loading style={{ width: "100%" }} visible={isLoading}>
        <div className="head">
          <div className="left">
            {/* {renderBtnColumn("point", "点")} */}
            {/* {renderBtnColumn("line", "线")} */}
            {renderBtnColumn("polygon", "框")}
          </div>
          <div className="right">
            <Button
              disabled={!taskId}
              onClick={() => {
                renderDelIcon();
              }}
            >
              删除
            </Button>
            <Button
              disabled={!taskId}
              onClick={() => {
                removeGraphFromKey();
              }}
            >
              清除
            </Button>
            {props.otherBtn && props.otherBtn()}
          </div>
        </div>

        <div
          className="point_line_polygon_content"
          id="polygon_content"
          style={{ background: pic ? "#f9fbff" : "#fff" }}
        >
          {pic ? null : (
            <div className="no_data">
              <img src={noData} alt="" />
              <div style={{ marginTop: 16 }}>
                请选择背景图片后，进行ROI绘制～
              </div>
            </div>
          )}
          <div id={id} className={pic ? "has_img" : "no_img"}>
            <div
              id={`${id}_delActDom`}
              style={{ height: "100%", position: "relative" }}
            />
          </div>
        </div>
      </Loading>
    </div>
  );
}

export default forwardRef(PointLinePolygon);

import { configureStore } from '@reduxjs/toolkit';
import user from './user';
import comment from './comment';

export default configureStore({
  reducer: {
    user: user,
    comment: comment,
  }
});

import React, { useState, useEffect, useMemo } from 'react';
import { Table, Button, Message, Modal } from '@yisa/webui';
import { FormDatePicker, ResultFlow, ExportModal } from '@/components';
import { useBoolean } from "ahooks";
import dayjs from "dayjs";
import ajax from '@/services';
import './index.scss'

function CylinderStatistics(props) {

  // 原始表单
  const defaultFormData = {
    year: dayjs().format('YYYY'),
  }
  const [formData, setFormData] = useState(defaultFormData)
  const [lastFormData, setLastFormData] = useState(formData)
  const [loading, { setTrue, setFalse }] = useBoolean(false)
  // 表单修改
  const changeValue = (v, k) => {
    setFormData(Object.assign({}, formData, { [k]: v }))
  }
  const [tableScrollY, setTableScrollY] = useState(200);

  const [resultData, setResultData] = useState({
    list: [],
    total: 0
  })

  const [exportVisible, setExportVisible] = useState(false);
  const [insideData, setInsideData] = useState({});
  const [insideVisible, setInsideVisible] = useState(false);
  const insideColumns = useMemo(() => {
    let baseColumns = [
      {
        title: '单位',
        dataIndex: 'org_name',
        key: 'org_name',
        width: 100,
      },
    ]
    if (insideData.useData && Array.isArray(insideData?.useData)) {
      insideData?.useData?.map((elem) => {
        baseColumns.push(
          {
            title: elem.day + '日',
            dataIndex: elem.day,
            key: elem.month,
            width: 100,
            render: (text, data) => {
              return <span>{elem.number ?? "--"}</span>
            }
          },
        )
      })
    }
    return baseColumns;
  }, [insideData])

  const columns = useMemo(() => {
    let base = [
      {
        title: '单位',
        dataIndex: 'org_name',
        key: 'org_name',
      }
    ]
    let extra = new Array(12).fill('').map((elem, index) => {
      return {
        title: (index + 1) + '月',
        dataIndex: (index + 1) + '月',
        key: (index + 1) + '月',
        render: (text, data) => {
          let useData = data.numbers.find(p => p.month == (index + 1))?.table_data
          return <span onClick={() => {
            // useData = new Array(30).fill('').map((q, i) => {
            //   return {
            //     day: i,
            //     number: parseInt(Math.random() * 100)
            //   }
            // })
            if (useData && Array.isArray(useData) && useData?.length) {
              setInsideData({
                useName: data.org_name,
                useMonth: (index + 1) + '月',
                useData: useData
              })
              setInsideVisible(true);
            }
          }} style={{
            'cursor': 'pointer',
            'color': useData && Array.isArray(useData) && useData?.length ? 'var(--primary-color)' : 'var(--base-color1-50)',
          }}>{text ?? "--"}</span>
        }
      }
    })
    return base.concat(extra);
  }, [])


  // 获取列表数据
  const getList = (params = formData) => {
    setTrue(true)
    setLastFormData(params)
    ajax.gasStatistics(params).then(res => {
      if (res.status === 20000) {
        const dataSource = res.data.map((item) => {
          const rowData = {
            key: item.org_id,
            org_name: item.org_name,
            numbers: item.numbers
          };

          item.numbers.forEach((num) => {
            const month = `${num.month}月`;
            rowData[month] = num.number;
          });

          return rowData;
        });
        setResultData({ list: dataSource })
      } else {
        Message.error(res.message)
      }
      setFalse(false)
    }).catch(err => console.log(err))
  }

  useEffect(() => {
    getList(formData)
  }, [])

  const reset = () => {
    setFormData(defaultFormData)
    getList(defaultFormData)
  }
  useEffect(() => {
    const handleCalcHeight = () => {
      try {
        const resultHeight = document.querySelector(".table-box")
          ? document.querySelector(".table-box").clientHeight - 89
          : 0;
        const thHeight = document.querySelector(".ysd-table-header")
          ? document.querySelector(".ysd-table-header").clientHeight
          : 0;
        const toolHeight = 32 + 20;
        const height = resultHeight - thHeight - toolHeight;
        setTableScrollY(Math.max(height, 200));
      } catch (err) {
        console.log(err);
      }
    };
    handleCalcHeight();
    window.addEventListener("resize", handleCalcHeight);

    return () => {
      window.removeEventListener("resize", handleCalcHeight);
    };
  }, [resultData.total]);

  return <div className="cylinder-statistics-pages">
    <div className="search">
      <div className="title">查询条件</div>

      <FormDatePicker
        className="search-item"
        picker="year"
        labelPosition="top"
        format="YYYY"
        showTime={true}
        yisaLabel="年份"
        value={formData.year}
        onChange={(e) => changeValue(e, "year")}
      />
      <div className="search-btn-box">
        <Button className="reset-btn" onClick={reset} >
          重置
        </Button>
        <Button type="primary" className='search-btn' onClick={() => getList(formData)}>
          搜索
        </Button>

      </div>
    </div>
    <div className="table-box">
      <nav className="total">
        <p>
          {/* 共<span> {resultData.total || 0} </span>条结果 */}
        </p>
        <Button className="export-btn" type="primary" onClick={() => setExportVisible(true)}>
          导出
        </Button>
      </nav>

      <ResultFlow
        loading={loading}
        resultData={resultData.list}
      >
        <div className="list">
          <Table
            className="table-warp"
            columns={columns}
            data={resultData.list}
            rowKey="id"
            bordered
            scroll={{
              y: tableScrollY,
            }}
            loading={loading}
            pagination={false}
          />
        </div>
      </ResultFlow>
    </div>
    <Modal
      title={insideData?.useName + insideData?.useMonth + '统计'}
      className='cylinder-detail-modal'
      visible={insideVisible}
      width={1790}
      maskClosable={false}
      // okText="提交"
      // cancelText="取消"
      hideCancel={true}
      onOk={() => {
        setInsideVisible(false);
      }}
      onCancel={() => {
        setInsideVisible(false);
      }}
    >
      <Table
        className="table-warp cylinder-detail-modal-table"
        columns={insideColumns}
        data={[
          { org_name: insideData?.useName }
        ]}
        rowKey="id"
        bordered
        scroll={{
          x: 1200,
        }}
        pagination={false}
      />
    </Modal>
    <ExportModal
      total={resultData?.total || resultData?.list?.length}
      formData={{ ...lastFormData, page: "", page_size: "" }}
      visible={exportVisible}
      onCancel={() => { setExportVisible(false) }}
      url='/summary/gas-statistics-export'
      ajaxType="post"
    />
  </div>
}

export default CylinderStatistics

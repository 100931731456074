import React, { useState, useEffect } from "react";
import { DatePicker } from "@yisa/webui";
import classnames from "classnames";
import dayjs from "dayjs";
import "./index.scss";

export default function FormDatePicker(props) {
  const {
    yisaLabel = "",
    labelPosition = "top",
    onChange = () => { },
    value = "",
    disabled = false,
    className = "",
    format = "YYYY-MM-DD HH:mm:ss",
    ...res
  } = props;
  const [dateRange, setDateRange] = useState(value);
  const onTimeChange = (value, dateString) => {
    setDateRange(dateString);
    onChange(dateString);
  };

  const momentDate = (date) => {
    if (date) {
      return dayjs(date, format);
    } else {
      return null;
    }
  };
  useEffect(() => {
    if (JSON.stringify(value) != JSON.dateRange) {
      setDateRange(value);
    }
  }, [value]);


  return (
    <div
      className={classnames({
        "cc-form-daterange": true,
        "form-daterange-top": labelPosition == "top",
        "form-daterange-left": labelPosition == "left",
        [className]: className != "",
      })}
    >
      {yisaLabel && <div className="cc-form-name">
        <label>{yisaLabel}</label>
      </div>
      }
      <div className="cc-form-content">
        <DatePicker
          value={momentDate(dateRange)}
          onChange={onTimeChange}
          format={format}
          disabled={disabled}
          {...res}
        />
      </div>
    </div>
  );
}

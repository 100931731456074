function Event() {
  this.events = [];
  this.listen = function (key, fn) {
    if (!this.events[key]) {
      this.events[key] = [];
      this.events[key].push(fn);
    }
  };
  this.cleanEvents = function (key) {
    const index = this.events.indexOf(key);
    if (index) {
      this.events.splice(index, 1);
    }
  };
}

// Event.prototype.listen = function (key, fn) {
//   if (!this.events[key]) {
//     this.events[key] = []
//   }
//   this.events[key][0] = fn
// }

Event.prototype.trigger = function () {
  const key = Array.prototype.shift.call(arguments);
  const fns = this.events[key];

  if (!fns || fns.length === 0) {
    return false;
  }

  fns.forEach((o, i) => {
    fns[i].apply(this, arguments);
  });
};

export default Event;

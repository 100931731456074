/**
 *@description  数据字典
 * */

export default {

  // 分页
  pageSizeOptions: ["30", "60", "180"],

  violationType: [{
    "label": "充装气瓶颜色异常",
    "value": "1"
  }, {
    "label": "作业人员人脸异常",
    "value": "2"
  }, {
    "label": "占道经营",
    "value": "3"
  }, {
    "label": "车辆违停",
    "value": "4"
  }, {
    "label": "宠物禁入",
    "value": "5"
  }, {
    "label": "吸烟",
    "value": "6"
  }, {
    "label": "垃圾桶未盖盖",
    "value": "7"
  }, {
    "label": "垃圾桶冒溢",
    "value": "8"
  }],
  roleType: [{
    "label": "超级管理员",
    "value": "1"
  }, {
    "label": "农贸市场监管单位",
    "value": "4"
  }, {
    "label": "液化气执法大队",
    "value": "5"
  }, {
    "label": "液化气执法中队",
    "value": "6"
  }, {
    "label": "农贸市场驻场管理员",
    "value": "7"
  }, {
    "label": "农贸市场负责人",
    "value": "8"
  }, {
    "label": "液化气单位负责人",
    "value": "9"
  }]
}

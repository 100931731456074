import React, { useState, useEffect, useRef } from 'react';
import { Table, Button, Pagination, Message, TreeSelect, Modal, Image } from '@yisa/webui';
import { FormDatePicker, ResultFlow, FormTreeSelect, FormSelect, FormInputGroup, ExportModal, BigImgSwitch } from '@/components';
import { useBoolean } from "ahooks";
import { character } from "@/utils";
import dayjs from "dayjs";
import ajax from '@/services';
import errorPng from "@/assets/images/error.png"
import noPng from "@/assets/images/no.png"
import './index.scss'
import ViewModal from './viewModal';

function Analysis(props) {

  // 原始表单
  const defaultFormData = {
    // // 经营单位名称
    // business_id: [],
    // //经营单位名类别
    // business_type: "-1",
    // // 违规行为
    // visolation_id: "-1",
    // // 违规数量
    // violation_count: [0, 100],
    // // 超时未反馈
    // timeout_count: [0, 100],
    // // 已整改数量
    // finish_count: [0, 100],
    // // 抓拍正确数量
    // right_count: [0, 100],
    // //抓拍错误数量
    // error_count: [0, 100],
    // // 抓拍设备
    // device_name: [],
    // 开始结束时间
    start_time: `${dayjs().subtract(1, 'week').format('YYYY-MM-DD')} 00:00:00`,
    end_time: `${dayjs().format('YYYY-MM-DD')} 23:59:59`,
    search_type: 'unit',
    // 页码
    page: 1,
    // 数据量
    page_size: character.pageSizeOptions[0],
  }

  const searchTypeDic = [
    { label: '按设备', value: 'device' },
    { label: '按算法', value: 'alg' },
    { label: '按单位', value: 'unit' },
  ]

  const [secondData, setSecondData] = useState([]);
  const [secondVisible, setSecondVisible] = useState(false);
  const [bigImgData, setBigImgData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [bigImgVisible, setBigImgVisible] = useState(false);

  const [formData, setFormData] = useState(defaultFormData)
  const [lastFormData, setLastFormData] = useState(formData)
  const [loading, { setTrue, setFalse }] = useBoolean(false)
  // 导出
  const [exportVisible, setExportVisible] = useState(false)
  // 经营单位列表
  const [businessList, setBusinessList] = useState([])
  // 抓拍设备列表
  const [devisceList, setDevisceList] = useState([])
  // 违规行为
  const [violationType, setViolationType] = useState([])
  // 表单修改
  const changeValue = (v, k) => {
    setFormData(Object.assign({}, formData, { [k]: v }))
  }
  const [tableScrollY, setTableScrollY] = useState(200);

  const [resultData, setResultData] = useState({
    list: [],
    total: 0
  })

  const baseSecondForm = {
    page: 1,
    page_size: character.pageSizeOptions[0],
  }
  const [secondForm, setSecondForm] = useState(baseSecondForm);
  const [secondId, setSecondId] = useState('');
  const [thirdId, setThirdId] = useState('');
  const [selectKey, setSelectKey] = useState([])
  const [selectVisible, setSelectVisible] = useState(false);

  const getSecondData = (params) => {
    let useKey = lastFormData.search_type + '_id'
    ajax.getSummarySecondList({
      start_time: lastFormData.start_time,
      end_time: lastFormData.end_time,
      page: 1,
      page_size: character.pageSizeOptions[0],
      device_id: useKey == 'device_id' ? secondId : 0,
      alg_id: useKey == 'alg_id' ? secondId : 0,
      unit_id: useKey == 'unit_id' ? secondId : 0,
      ...params,
    }).then(res => {
      if (res.status === 20000) {
        setSecondData(res.data);
        setSecondVisible(true);
      } else {
        Message.error(res.message)
      }
    }).catch(err => {
      let temp = new Array(17).fill({
        target_id: '111'
      })
      setSecondData({
        total: 100,
        list: temp
      })
      setSecondVisible(true);
      console.log(err)
    })
  }

  useEffect(() => {
    if (secondId) {
      getSecondData();
    }
  }, [secondId])

  const columns = [
    {
      title: "统计目标",
      dataIndex: "target_name",
      align: "center",
    },
    {
      title: '已读/总数',
      dataIndex: 'read_num',
      align: 'center',
      render: (text, data) => {
        return <span onClick={() => {
          setSecondId(data.target_id);
        }} style={{
          'cursor': 'pointer',
          'color': 'var(--base-color1-50)'
        }}>{data.read_num ?? "--"}/{(data.unread_num ?? '-') + (data.read_num ?? '-')}</span>
      }
    },
    {
      title: "正确率",
      dataIndex: "correct_rate",
      align: "center",
    },
  ]

  const secondColumns = [
    {
      title: "事件编号",
      dataIndex: "task_number",
      align: "center",
    },
    {
      title: "经营单位名称",
      dataIndex: "business_name",
      align: "center",
    },
    {
      title: "抓拍设备",
      dataIndex: "device_name",
      align: "center",
    },
    {
      title: "违规行为",
      dataIndex: "behavior",
      align: "center",
    },
    {
      title: "抓拍时间",
      dataIndex: "capture_time",
      align: "center",
    },
    {
      title: "现场图片",
      dataIndex: "pic_path",
      align: "center",
      width: 190,
      render: (text, data) => {
        // '	http://192.168.4.149/static/media/test.24ab5859f07e15f026ef.png'
        let src = text ? text : noPng
        return <div className='warn_img'>
          <Image src={src} errorSrc={errorPng}
            onClick={() => {
              if (text) {
                setBigImgVisible(true);
                setCurrentIndex(0);
                setBigImgData([src])
              }
            }} />
        </div>
      }
    },
    {
      title: '状态',
      dataIndex: 'status',
      align: 'center',
      render: (text, data) => {
        return <span style={{
          color: text == '100' ? '#33ff3d' : '#3377ff',
          cursor: 'pointer'
        }} onClick={() => {
          if (data.id && data.status_text !== '未读') {
            setThirdId(data.id);
          }
        }}>{data.status_text ?? "--"}</span>
      }
    },
    {
      title: '识别结果',
      dataIndex: 'is_right',
      align: 'center',
      render: (text, data) => {
        return <span style={{
          color: text == '2' ? 'var(--base-color1-50)' : 'var(--primary-color)'
        }}>{data.is_right_text}</span>
      }
    },
  ]



  // 获取列表数据
  const getList = (params = formData) => {
    setTrue(true)
    setLastFormData(params)
    ajax.getSummaryList(params).then(res => {
      if (res.status === 20000) {
        setResultData(res.data)
      } else {
        Message.error(res.message)
      }
      setFalse(false)
    }).catch(err => {
      let temp = new Array(17).fill({
        target_id: '111'
      })
      setResultData({
        total: 100,
        list: temp
      })
      setFalse(false)
      console.log(err)
    })
  }

  // 获取接收部门
  const getBusinessList = () => {
    ajax
      .getBusiness()
      .then((res) => {
        const { status, message, data } = res;
        if (status === 20000) {
          let arr = [{ label: "全部", value: "-1", children: data }]
          setBusinessList(arr);
        } else {
          Message.error(message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // 违规行为
  const getViolationType = () => {
    ajax
      .getViolationType()
      .then((res) => {
        const { status, message, data } = res;
        if (status === 20000) {
          setViolationType(data);
        } else {
          Message.error(message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // 获取设备
  const getDeviceList = () => {
    ajax
      .getDeviceCapture()
      .then((res) => {
        const { status, message, data } = res;
        if (status === 20000) {
          let arr = [{ label: "全部", value: "-1", children: data }]
          setDevisceList(arr);
        } else {
          Message.error(message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getBusinessList()
    getDeviceList()
    getViolationType()
    getList(formData)
  }, [])

  const reset = () => {
    setFormData(defaultFormData)
    getList(defaultFormData)
  }
  useEffect(() => {
    const handleCalcHeight = () => {
      try {
        const resultHeight = document.querySelector(".table-box")
          ? document.querySelector(".table-box").clientHeight - 89
          : 0;
        const thHeight = document.querySelector(".ysd-table-header")
          ? document.querySelector(".ysd-table-header").clientHeight
          : 0;
        const toolHeight = 32 + 20;
        const height = resultHeight - thHeight - toolHeight;
        setTableScrollY(Math.max(height, 200));
      } catch (err) {
        console.log(err);
      }
    };
    handleCalcHeight();
    window.addEventListener("resize", handleCalcHeight);

    return () => {
      window.removeEventListener("resize", handleCalcHeight);
    };
  }, [resultData.total]);

  return <div className="analysis-pages">
    <div className="search">
      <div className="title">查询条件</div>
      {/* <FormTreeSelect
        labelPosition="top"
        yisaLabel="经营单位名称"
        placeholder="请选择行政区域"
        className="search-item"
        value={formData.business_id}
        onChange={(v) => changeValue(v, "business_id")}
        fieldNames={{ title: "label", value: "label", key: "value" }}
        treeData={businessList}
      />
      <FormSelect
        labelPosition="top"
        yisaLabel="经营单位类别"
        allowClear={false}
        options={[{
          label: "农贸市场",
          value: "1",
        }, {
          label: "液化气单位",
          value: "2"
        }]}
        className="search-item"
        placeholder="请选择经营单位类别"
        showUnlimit={true}
        value={formData.business_type}
        onChange={(v) => changeValue(v, "business_type")}
      />

      <FormSelect
        labelPosition="top"
        yisaLabel="违规行为"
        allowClear={false}
        options={violationType}
        showUnlimit={true}
        className="search-item"
        placeholder="请选择违规行为"
        value={formData.violation_id}
        onChange={(v) => changeValue(v, "violation_id")}
      />

      <FormInputGroup
        className="search-item"
        labelPosition="top"
        type='number'
        yisaLabel='违规数量'
        defaultValueMin={formData.violation_count[0]}
        defaultValueMax={formData.violation_count[1]}
        onChange={(e) => {
          setFormData({ ...formData, violation_count: [e.min, e.max] })
        }}
      />
      <FormInputGroup
        className="search-item"
        labelPosition="top"
        type='number'
        yisaLabel='超时未反馈数量'
        defaultValueMin={formData.timeout_count[0]}
        defaultValueMax={formData.timeout_count[1]}
        onChange={(e) => {
          setFormData({ ...formData, timeout_count: [e.min, e.max] })
        }}
      />
      <FormInputGroup
        className="search-item"
        labelPosition="top"
        type='number'
        yisaLabel='已整改数量'
        defaultValueMin={formData.finish_count[0]}
        defaultValueMax={formData.finish_count[1]}
        onChange={(e) => {
          setFormData({ ...formData, finish_count: [e.min, e.max] })
        }}
      />
      <FormInputGroup
        className="search-item"
        labelPosition="top"
        type='number'
        yisaLabel='抓拍结果正确数量'
        defaultValueMin={formData.right_count[0]}
        defaultValueMax={formData.right_count[1]}
        onChange={(e) => {
          setFormData({ ...formData, right_count: [e.min, e.max] })
        }}
      />
      <FormInputGroup
        className="search-item"
        labelPosition="top"
        type='number'
        yisaLabel='抓拍结果错误数量'
        defaultValueMin={formData.error_count[0]}
        defaultValueMax={formData.error_count[1]}
        onChange={(e) => {
          setFormData({ ...formData, error_count: [e.min, e.max] })
        }}
      />
      <FormTreeSelect
        labelPosition="top"
        yisaLabel="抓拍设备"
        placeholder="请选择抓拍设备"
        className="search-item"
        value={formData.device_name}
        onChange={(v) => changeValue(v, "device_name")}
        fieldNames={{ title: "label", key: "value" }}
        treeData={devisceList}
      /> */}
      <FormSelect
        labelPosition="top"
        yisaLabel="查询方式"
        allowClear={false}
        options={searchTypeDic}
        showUnlimit={false}
        className="search-item"
        placeholder="请选择查询方式"
        value={formData.search_type}
        onChange={(v) => changeValue(v, "search_type")}
      />
      <FormDatePicker
        className="search-item"
        labelPosition="top"
        format="YYYY-MM-DD HH:mm:ss"
        showTime={true}
        yisaLabel="开始时间"
        value={formData.start_time}
        onChange={(e) => changeValue(e, "start_time")}
      />
      <FormDatePicker
        className="search-item"
        labelPosition="top"
        format="YYYY-MM-DD HH:mm:ss"
        showTime={true}
        yisaLabel="结束时间"
        value={formData.end_time}
        onChange={(e) => changeValue(e, "end_time")}
      />
      <div className="search-btn-box">
        <Button className="reset-btn" onClick={reset} >
          重置
        </Button>
        <Button type="primary" className='search-btn' onClick={() => getList(formData)}>
          搜索
        </Button>

      </div>
    </div>
    <div className="table-box">
      <nav className="total">
        <p>
          共<span> {resultData.total || 0} </span>条结果
        </p>
        <Button className="export-btn" type="primary" onClick={() => setExportVisible(true)}>
          导出
        </Button>
      </nav>

      <ResultFlow
        loading={loading}
        resultData={resultData.list}
      >
        <div className="list">
          <Table
            className="table-warp"
            columns={columns}
            data={resultData.list}
            rowKey="id"
            bordered
            scroll={{
              y: tableScrollY,
            }}
            loading={loading}
            pagination={false}
          />
        </div>
        <Pagination
          className="pagination-common"
          showQuickJumper
          showSizeChanger
          size='default'
          position={['bottomCenter']}
          current={lastFormData.page}
          total={resultData.total}
          pageSize={lastFormData.page_size}
          onChange={(page, pageSize) => {
            let obj = {};
            if (pageSize !== lastFormData.page_size) {
              obj.page = 1
              obj.page_size = pageSize
            } else {
              obj.page = page
            }
            let newParams = Object.assign({}, lastFormData, obj);
            getList(newParams)
          }}
          pageSizeOptions={character.pageSizeOptions}
        />
      </ResultFlow>
    </div>
    <ExportModal
      total={resultData?.total}
      // formData={{ ...lastFormData, page: "", page_size: "" }}
      formData={{
        start_time: lastFormData.start_time,
        end_time: lastFormData.end_time,
        search_type: lastFormData.search_type
      }}
      visible={exportVisible}
      onCancel={() => { setExportVisible(false) }}
      // url='/summary/export'
      url='/summary/analysis-export'
      ajaxType="post"
    />
    <ExportModal
      total={selectKey.length}
      formData={{ ids: selectKey }}
      visible={selectVisible}
      onCancel={() => { setSelectVisible(false); }}
      url='/warning/export'
      ajaxType="post"
    // callback={() => { setSelectKey([]) }}
    />
    <Modal
      title={'数据详情'}
      className='cylinder-detail-modal'
      visible={secondVisible}
      width={1790}
      maskClosable={false}
      // okText="提交"
      // cancelText="取消"
      escToExit={!!!thirdId}
      hideCancel={true}
      onOk={() => {
        setSecondForm(baseSecondForm);
        setSecondVisible(false);
        setSecondId("");
        setSelectKey([]);
      }}
      onCancel={() => {
        setSecondForm(baseSecondForm);
        setSecondVisible(false);
        setSecondId("");
        setSelectKey([]);
      }}
    >
      <div className="export-div">
        {
          selectKey.length ?
            <Button className="export-btn" type="primary" onClick={() => setSelectVisible(true)}>
              导出
            </Button> : <></>
        }
      </div>
      <Table
        className="table-warp cylinder-detail-modal-table"
        columns={secondColumns}
        data={secondData?.list ?? []}
        rowKey="id"
        bordered
        scroll={{
          x: 1200,
          y: 400
        }}
        rowSelection={
          ({
            type: "checkbox",
            checkAll: true,
            onChange: (selectedRowKeys, selectedRows) => {
              setSelectKey(selectedRowKeys)
            },
          })
        }
        rowKey="id"
        pagination={false}
      />
      <Pagination
        className="pagination-common"
        showQuickJumper
        showSizeChanger
        size='default'
        position={['bottomCenter']}
        current={secondForm.page}
        total={secondData?.total ?? 0}
        pageSize={secondForm.page_size}
        onChange={(page, pageSize) => {
          let obj = {};
          if (pageSize !== secondForm.page_size) {
            obj.page = 1
            obj.page_size = pageSize
          } else {
            obj.page = page
          }
          let newParams = Object.assign({}, secondForm, obj);
          setSecondForm(newParams);
          getSecondData(newParams);
        }}
        pageSizeOptions={character.pageSizeOptions}
      />
    </Modal>
    <BigImgSwitch
      visible={bigImgVisible}
      currentIndex={currentIndex}
      onCancel={() => setBigImgVisible(false)}
      yisaData={bigImgData}
      modalWidth={1400}
      changeNext={(v) => {
        setCurrentIndex(v);
      }}
      changePrev={(v) => {
        setCurrentIndex(v);
      }}
    />
    <ViewModal thirdId={thirdId} setThirdId={setThirdId} />
  </div>
}

export default Analysis

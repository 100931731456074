import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import './index.scss';

export default function ImgZoom(props) {
  const {
    src,
    scaleMin = 0.4,
    scaleMax = 2.3,
  } = props;


  //图片的尺寸
  const [picSize, setPicSize] = useState({ x: 0, y: 0 })
  const [xy, setXy] = useState({ x: 0, y: 0 });
  const [scale, setScale] = useState(1);
  // 鼠标是否按下
  const [isPointerdown, setIsPointerdown] = useState(false);
  //  相对于上一次pointermove移动差值
  const [moveDiff, setMoveDiff] = useState({ x: 0, y: 0 });
  // 用于计算diff
  const [lastMove, setLastMove] = useState({ x: 0, y: 0 });

  const containerRef = useRef(null);
  const imgRef = useRef(null);

  const getImgSize = (naturalWidth, naturalHeight, maxWidth, maxHeight) => {
    const imgRatio = naturalWidth / naturalHeight;
    const maxRatio = maxWidth / maxHeight;
    let width, height;
    // 如果图片的实际宽高比>=容器显示的宽高比
    if (imgRatio >= maxRatio) {
      if (naturalWidth > maxWidth) {
        width = maxWidth;
        height = maxWidth / naturalWidth * naturalHeight;
      } else {
        width = naturalWidth;
        height = naturalHeight;
      }
    } else {
      if (naturalHeight > maxHeight) {
        width = maxHeight / naturalHeight * naturalWidth;
        height = maxHeight;
      } else {
        width = naturalWidth;
        height = naturalHeight;
      }
    }
    return { x: width, y: height }
  }

  const handleImgLoad = () => {
    let _picSize = getImgSize(imgRef.current.naturalWidth, imgRef.current.naturalHeight, containerRef.current.clientWidth, containerRef.current.clientHeight);
    setPicSize(_picSize);
    imgRef.current.width = _picSize.x;
    imgRef.current.height = _picSize.y;
    let x = (containerRef.current.clientWidth - _picSize.x) / 2;
    let y = (containerRef.current.clientHeight - _picSize.y) / 2;
    setXy({ x, y });
    imgRef.current.style = `transform: translate(${x}px, ${y}px) scale(1)`
  }

  const handleImgDown = (e) => {
    e.preventDefault()
    setIsPointerdown(true);
    // 用于将img元素指定为未来指针事件的捕获目标，指针后续的事件将以捕获元素为目标直到捕获被释放
    imgRef.current.setPointerCapture(e.pointerId)
    setLastMove({
      x: e.clientX,
      y: e.clientY
    })
  }

  const handleImgMove = (e) => {
    if (!isPointerdown) return false;
    const client = { x: e.clientX, y: e.clientY };
    const _diff = {
      x: client.x - lastMove.x,
      y: client.y - lastMove.y
    }
    setMoveDiff(_diff)
    setLastMove(client);
    setXy({
      x: xy.x + _diff.x,
      y: xy.y + _diff.y
    })
    imgRef.current.style = `transform: translate(${xy.x + _diff.x}px, ${xy.y + _diff.y}px) scale(${scale})`;

  }

  const handleImgUp = (e) => {
    if (!isPointerdown || e.type == "pointercancel") return false
    setIsPointerdown(false);
  }

  const handleImgWheel = (e) => {
    // console.log(e)
    let ratio = 1.1;
    // 缩小
    if (e.deltaY > 0) {
      ratio = 0.9;
    }
    let _scale = scale * ratio;
    if (_scale > scaleMax) {
      ratio = scaleMax / scale;
      _scale = scaleMax;
    } else if (_scale < scaleMin) {
      ratio = scaleMin / scale;
      _scale = scaleMin;
    }
    let x = xy.x, y = xy.y;
    // 目标元素是img说明鼠标在img上，以鼠标位置为缩放中心，否则默认以图片中心点为缩放中心
    if (e.target.tagName === 'IMG') {
      const origin = {
        x: (ratio - 1) * picSize.x * 0.5,
        y: (ratio - 1) * picSize.y * 0.5
      };
      // 计算偏移量
      // 容器盒子的可是窗口距离
      let containerXY = containerRef.current.getBoundingClientRect();
      x -= (ratio - 1) * (e.clientX - containerXY.x - x) - origin.x;
      y -= (ratio - 1) * (e.clientY - containerXY.y - y) - origin.y;
    }
    imgRef.current.style = `transform: translate(${x}px, ${y}px) scale(${_scale})`
    setScale(_scale);
    setXy({ x, y })
  }

  useEffect(() => {
    setScale(1)
    setLastMove({ x: 0, y: 0 })
  }, [src])

  return (
    <div
      ref={containerRef}
      className="img-container"
      onWheel={handleImgWheel}
    >

      <img
        ref={imgRef}
        src={src}
        onLoad={handleImgLoad}
        onPointerDown={handleImgDown}
        onPointerMove={handleImgMove}
        onPointerUp={handleImgUp}
        onPointerCancel={handleImgUp}
      />
    </div>
  )
}
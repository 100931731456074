import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Layout } from '@yisa/webui';
import { useDispatch, useSelector } from 'react-redux';
import { setSidebarWidth } from '@/store/comment';
import Footer from './Footer';
import Breadcrumb from './Breadcrumb';
import Menu from './Menu';
import RightTools from './RightTools';
import fullLogo from './'
import './index.scss'


function Page(props) {

  const timer = useRef(0)

  const layout = useSelector((state) => {
    return state.comment.layout
  })
  const { route_index } = useSelector(state => state?.user?.userInfo) || {}

  const dispatch = useDispatch()

  const [inlineCollapsed, setInlineCollapsed] = useState(false)

  const [width, setWidth] = useState('268px')

  const onCollapsed = (value) => {
    setInlineCollapsed(value)
  }

  const resize = () => {
    let width = document.body.clientWidth
    if (width > 1440) {
      setWidth('268px')
    } else {
      setWidth('220px')
    }
  }

  useEffect(() => {
    clearTimeout(timer.current)
    timer.current = setTimeout(() => {
      dispatch(setSidebarWidth())
    }, 300)
  }, [layout, inlineCollapsed, width])

  useEffect(() => {
    resize()
    window.addEventListener('resize', resize)
    return () => {
      clearTimeout(timer.current)
      window.removeEventListener('resize', resize)
    }
  }, [])

  return <Layout
    type={layout}
    leftWidth={!inlineCollapsed ? width : '60px'}
    logo={<Link className='layout-brand' to={route_index || '/home'} title={sysConfig.title}>
      {
        inlineCollapsed ?
          <img alt='logo' className='logo' src={require("@/assets/images/full-logo.png")} /> :
          <><img alt='logo' className='full-logo' src={require("@/assets/images/full-logo.png")} /><span className="">AI违法抓拍处置平台</span></>
      }
    </Link>}
    menu={<Menu onCollapsed={onCollapsed} type={layout} collapsed={inlineCollapsed} />}
    rightTools={<RightTools />}
    footer={<Footer />}
    breadcrumb={<Breadcrumb />}
  >
    {props.children}
  </Layout>
}


export default Page

import React from 'react'
import Left from './left'
import Right from './right'
import './index.scss'

function Market() {

  return (
    <div className="marker-body">
      <Left />
      <Right />
    </div>
  )
}

export default Market

import React, { useState, useRef, useEffect, useMemo } from "react";
import { Modal, Message, Table, Input, Tabs, Checkbox } from '@yisa/webui';
import { useUpdateEffect } from "ahooks"
import PointLinePolygon from './PointLinePolygon'
import { FormSelect } from '@/components';
import ajax from '@/services';
import { jsToWkt, wktToJs, transformConfigdataWKTToJs } from "./PointLinePolygon/util";
import { CloseCircleOutlined } from '@yisa/webui/es/Icon';


function ROIModal(props) {
  const { visible, onCancel, data } = props
  const res = {
    picPath: data.loc_img,
    roi: [],
    sdk_config: [],
    initBtns: [],
    mainKey: 1, // 变化的时候会重新渲染
  };
  const [polygonConfig, setPolygonConfig] = useState(res);
  // 算法
  const [taskList, setTaskList] = useState([])
  const [selectedTaskIds, setSelectedTaskIds] = useState([])
  const selectedTasks = useMemo(() => {
    return taskList.filter(elem => selectedTaskIds.includes(elem.key))
  }, [taskList, selectedTaskIds])
  const [taskId, setTaskId] = useState("")
  const ref = useRef();
  useEffect(() => {
    if (visible) {
      getLocTask()
    }
  }, [visible])
  useEffect(() => {
    if (taskId) {
      getAlgconf(taskId)
    } else {
      setPolygonConfig({ ...res })
    }
  }, [taskId])

  const handleSelectTasks = (v) => {
    if (!v.includes(taskId)) {
      setTaskId(v?.[0] || '')
    }
    setSelectedTaskIds(v)
  }

  // 查询设备所挂载的算法
  const getLocTask = () => {
    ajax
      .getLocTask({
        loc_id: data.id
      })
      .then((res) => {
        const { status, message } = res;
        if (status === 20000) {
          const { available, enable } = res?.data || {}
          const list = Array.isArray(available) ? available.map((item) => {
            return {
              key: item.alg_id,
              value: item.alg_id,
              name: item.alg_name,
              label: item.alg_name
            }
          }) : []
          const selectedIds = Array.isArray(enable) ? enable : []
          setTaskList(list)
          setSelectedTaskIds(selectedIds)
          setTaskId(selectedIds?.[0] || '')
        } else {
          Message.error(message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  // 获取设备的算法配置
  const getAlgconf = (id) => {
    ajax
      .getAlgconf({
        code: data.device_id,
        alg_id: id
      })
      .then((res) => {
        const { status, message } = res;
        if (status === 20000) {
          let data = res.data
          let obj = {
            id: data.id,
            sdk_config: JSON.parse(data.sdk_config),
            roi: data.roi_config !== "null" ? transformConfigdataWKTToJs(JSON.parse(data.roi_config).map((item) => {
              return {
                ...item,
                value: JSON.parse(item.value)
              }
            })) : [],
            initBtns:
              data.roi_config !== "null" ? JSON.parse(data.roi_config).map((item) => {
                return {
                  id: item.id,
                  name: item.name,
                  type: item.type,
                  key: item.key,
                  platformId: item.platformId,
                  algId: item.algId
                }
              }) : [{}],
            deviceCode: data.deviceCode,
            algId: data.algId,
            mainKey: polygonConfig.mainKey + 1
          }

          setPolygonConfig({ ...polygonConfig, ...obj })
        } else {
          Message.error(message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleAdd = () => {
    if (!taskId) return
    let arr = polygonConfig.sdk_config
    let obj = { key: "", value: "", type: "" }
    arr.push(obj)
    setPolygonConfig({ ...polygonConfig, sdk_config: arr })
  }

  // 更新
  const addAlgconf = () => {
    let obj = {
      code: data.device_id,
      alg_id: taskId,
      selected_alg_ids: selectedTaskIds,
      sdk_config: JSON.stringify(polygonConfig.sdk_config),
      roi_config: JSON.stringify(jsToWkt(polygonConfig.initBtns, polygonConfig.roi))
    }
    updateAlg()
    ajax
      .addAlgconf(obj)
      .then((res) => {
        const { status, message, data } = res;
        if (status === 20000) {
          Message.success(res.message)
        } else {
          Message.error(message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const updateAlg = () => {
    ajax.updateAlgs({
      device: data.id,
      algs: selectedTaskIds
    }).then(() => { }).catch(err => {
      console.log(err)
    })
  }

  useEffect(() => {
    setPolygonConfig({ ...polygonConfig, picPath: data.loc_img, mainKey: polygonConfig.mainKey + 1 })
  }, [data])

  return <Modal
    title='编辑'
    className='edit-roi-modal'
    unmountOnExit={true}
    visible={visible}
    width={1720}
    maskClosable={false}
    okText="提交"
    cancelText="取消"
    onOk={() => {
      addAlgconf()
    }}
    onCancel={() => {
      onCancel()
      setTaskId("")
      setPolygonConfig(res)
    }}
  >
    <div className="top-tab">
      <h3>选择算法</h3>
      <div className="task-list">
        <Checkbox.Group options={taskList} value={selectedTaskIds} onChange={handleSelectTasks} />
      </div>
    </div>
    <div className="top-tab">
      <h3>编辑算法</h3>
      {
        !!selectedTasks?.length && <Tabs type='line' defaultActiveKey="1" activeKey={taskId} data={selectedTasks}
          onChange={setTaskId} />
      }
      {
        !selectedTasks?.length && <p>请选择算法</p>
      }
    </div>
    <div className="save-content">

      <div className="modal-left">
        <PointLinePolygon
          ref={ref} // 可获取组件内部方法
          taskId={taskId}
          mainKey={polygonConfig.mainKey}
          data={polygonConfig}
          drawEnd={(msg) => {
            //  画完回调
            const { drawData } = msg;
            setPolygonConfig({ ...polygonConfig, roi: drawData });
          }}
        ></PointLinePolygon>
      </div>
      <div className="modal-right">
        <h3>算法配置</h3>
        <div className={taskId ? "add-list" : "add-list add-list-disabled"} onClick={handleAdd}>添加一条</div>
        <div className="list-top">
          <div className="tahead">键</div>
          <div className="tahead">值</div>
          <div className="tahead">类型</div>
        </div>
        <div className="list-box">
          {
            polygonConfig.sdk_config && polygonConfig.sdk_config.length > 0 ?
              polygonConfig.sdk_config.map((item, index) => {
                return <div className="inp-box">
                  <div className="inp">
                    <Input
                      className="cc-edit-input"
                      placeholder=""
                      value={item.key}
                      onChange={(e) => {
                        let arr = polygonConfig.sdk_config
                        let obj = { ...item, key: e.target.value }
                        arr.splice(index, 1, obj)
                        setPolygonConfig({ ...polygonConfig, sdk_config: arr })
                      }}
                    />
                  </div>
                  <div className="inp">
                    <Input
                      className="cc-edit-input"
                      placeholder=""
                      value={item.value}
                      onChange={(e) => {
                        let arr = polygonConfig.sdk_config
                        let obj = { ...item, value: e.target.value }
                        arr.splice(index, 1, obj)
                        setPolygonConfig({ ...polygonConfig, sdk_config: arr })
                      }}
                    />
                  </div>
                  <div className="inp1">
                    <FormSelect
                      labelPosition="top"
                      yisaLabel=""
                      showClose
                      options={[{
                        label: "int",
                        value: "int",
                      }, {
                        label: "float",
                        value: "float",
                      }, {
                        label: "string",
                        value: "string",
                      }, {
                        label: "boolean",
                        value: "boolean",
                      }, {
                        label: "array",
                        value: "array",
                      }]}
                      showUnlimit={false}
                      className="search-item"
                      placeholder="请选择"
                      value={item.type}
                      onChange={(e) => {
                        let arr = polygonConfig.sdk_config
                        let obj = { ...item, type: e }
                        arr.splice(index, 1, obj)
                        setPolygonConfig({ ...polygonConfig, sdk_config: arr })
                      }}
                    />
                    <CloseCircleOutlined onClick={() => {
                      let arr = polygonConfig.sdk_config
                      arr.splice(index, 1)
                      setPolygonConfig({ ...polygonConfig, sdk_config: arr })
                    }} />
                  </div>
                </div>
              }) : <div className="no-list">没有数据</div>
          }
        </div>
      </div>
    </div>
  </Modal>
}

export default ROIModal

import React, { useEffect, useRef, useState } from 'react'
import { Loading } from '@yisa/webui'
import ajax from '@/services'

function Left() {

  const chartRef = useRef()
  const [loading, setLoading] = useState(true)
  const [xData, setXData] = useState([])
  const [yData, setYData] = useState([])

  const handleGetData = () => {
    ajax.getGasStatistics().then(res => {
      const { range, list } = res?.data || {}
      if (Array.isArray(range) && range.length && Array.isArray(list) && list.length) {
        setXData(range)
        setYData(list.map(elem => ({
          type: 'bar',
          name: elem.unit_name,
          data: elem.nums
        })))
      } else {
        setXData([])
        setYData([])
      }
    }).catch(err => {
      console.log(err)
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    handleGetData()
    const handleResize = () => {
      chartRef.current && chartRef.current.resize()
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
      chartRef.current && chartRef.current.dispose()
    }
  }, [])

  useEffect(() => {
    if (xData.length && yData.length) {
      handleInitChart()
    } else {
      chartRef.current && chartRef.current.dispose()
    }
  }, [xData, yData])

  const handleInitChart = () => {
    const chartDom = document.getElementById('markerChart')
    const myChart = echarts.getInstanceByDom(chartDom) ?? echarts.init(chartDom)
    const option = {
      color: ['rgba(0,160,255,.9)', 'rgba(255,179,65,.9)', 'rgba(255,65,65,.9)'],
      legend: {
        top: 20,
        show: true,
        textStyle: {
          color: '#fff'
        }
      },
      grid: {
        top: 80,
        left: 60,
        right: 60,
        bottom: 60
      },
      tooltip: {
        show: true,
        trigger: 'axis',
      },
      xAxis: {
        type: 'category',
        data: xData,
        axisLabel: {
          color: '#fff'
        },
        axisPointer: {
          type: 'shadow'
        },
        axisLine: {
          lineStyle: {
            color: ['rgba(255,255,255,.5)'],
          }
        }
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          color: '#fff',
        },
        splitNumber: 5,
        splitLine: {
          lineStyle: {
            color: ['rgba(255,255,255,.5)'],
            type: 'dashed'
          }
        }
      },
      series: yData
    }
    myChart.setOption(option)
    chartRef.current = myChart
  }

  return (
    <div className="market-content market-content-left">
      {
        loading && <div className="loading">
          <Loading spinning />
        </div>
      }
      {
        !loading && !xData.length && <div className="loading">
          暂无统计数据
        </div>
      }
      <div id="markerChart" className="market-chart" />
    </div>
  )
}

export default Left

import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import ComponentWrap from './ComponentWrap'
import { useSelector } from "react-redux";
import router from './router.config'
import WaterMark from './WaterMark'
import pages from '@/pages'
import { getToken } from '@/utils'

function APPRouter() {

  const token = getToken()
  const routes = useSelector((state) => {
    return state.user.route
  })
  const { route_index } = useSelector(state => state?.user?.userInfo) || {}
  const createRoute = (item) => {
    if (true && routes.length) {
      return <Route exact path={item.path} key={item.path}>
        {
          item.needPermission && !routes.includes(item.path) && false ?
            <Redirect to="/403" /> :
            <ComponentWrap data={{
              ...item,
              component: null
            }}>
              <item.component></item.component>
            </ComponentWrap>
        }
      </Route>
    } else {
      return <Redirect to={'/login'} />
    }
  }

  return <>
    <WaterMark />
    <Switch>
      <Route path="/login" component={pages.Login} />
      {
        token && <>
          <Route exact path='/' key='/'>
            <Redirect to={route_index || (routes?.[0] || '/403')} />
          </Route>
          {
            routes.length && router.map((item, index) => {
              if (item.children && item.children.length) {
                return (
                  <Route path={item.path} key={item.path}>
                    <item.component>
                      <Switch>
                        {
                          item.children.map((item2) => {
                            return createRoute(item2)
                          })
                        }
                      </Switch>
                    </item.component>
                  </Route>
                )
              } else {
                return createRoute(item)
              }
            })
          }
        </>
      }
    </Switch>
  </>
}


export default APPRouter


import React from "react";
import { Input } from "@yisa/webui";
import classnames from "classnames";
import { SearchOutlined } from "@yisa/webui/es/Icon";
import "./index.scss";

export default function FormInput(props) {
  const {
    yisaLabel = "",
    labelPosition = "top",
    onChange = () => { },
    placeholder = "请输入",
    value = "",
    disabled = false,
    className = "",
    onPressEnter = () => { },
    onBlur = () => { },
    isShowAfterIcon = false,
    isRequired = false,  // 标题前的*
    error = "",
  } = props;

  return (
    <div
      className={classnames({
        "cc-form-input": true,
        "form-input-top": labelPosition == "top",
        "form-input-left": labelPosition == "left",
        [className]: className != "",
      })}
    >
      {yisaLabel && (
        <div className="cc-form-name">
          {isRequired ? <label className="required">*</label> : null}
          <label>{yisaLabel}</label>
        </div>
      )}
      <div className="cc-form-content">
        <Input
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={disabled}
          onBlur={onBlur}
          onPressEnter={onPressEnter}
          error={error}
        />
        {isShowAfterIcon ? <SearchOutlined
          className="search-icon"
        /> : null}
      </div>
    </div>
  );
}

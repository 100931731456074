import React, { useEffect, useRef } from 'react'
import { ErrorBoundary } from '@/components'
import { useDispatch, useSelector } from 'react-redux';
import { setRouterData, setSkin } from '@/store/comment';
import { getParams } from "@/utils";
import { useLocation, useHistory } from "react-router-dom";
import qs from 'qs';

let oldSkin = ''

// 组件包装
function ComponentWrap(props) {

  const {
    data = {},
  } = props

  const dispatch = useDispatch();

  const location = useLocation()

  const history = useHistory()
  const pageData = useRef({
    destroy: false,
    params: getParams(location.search)
  })

  const skin = useSelector((state) => {
    return state.comment.skin
  });


  const onChangePath = (data) => {
    if (!pageData.current.destroy) {
      history.replace(location.pathname + '?' + qs.stringify(data))
    }
  }

  const isDestroy = () => {
    return pageData.current.destroy
  }

  // 组件渲染之前
  const beforeEach = () => {

    // 滚动条回到顶部
    try {
      document.getElementsByClassName('ysd-layout-horizontal-top-content-body')[0].scrollTop = 0
    } catch (error) {
    }
    try {
      document.getElementsByClassName('ysd-layout-vertical-top-right-content-body')[0].scrollTop = 0
    } catch (error) {
    }

    // 需要修改面包屑的
    const arr = ["/tabledetail"]
    let datas = JSON.parse(JSON.stringify(data))
    if (arr.includes(datas.path)) {
      datas.breadcrumb.push({ text: pageData.current.params.name || '' })
    }
    dispatch(setRouterData(datas))
    sessionStorage.setItem('path', datas.path)
    // 固定主题
    if (data.fixedTheme) {
      if (!oldSkin) {
        oldSkin = skin
      }
      dispatch(setSkin(data.fixedTheme))
      try {
        document.getElementById('pifu-setting').style.display = 'none'
      } catch (error) {
      }
    }

    // 还原主题
    if (oldSkin && !data.fixedTheme) {
      try {
        document.getElementById('pifu-setting').style.display = 'block'
      } catch (error) {
      }
      dispatch(setSkin(oldSkin))
      oldSkin = ''
    }

  }


  // 组件销毁之前
  const afterEach = () => {
    // 页面已销毁
    pageData.current.destroy = true
  }

  useEffect(() => {
    beforeEach()
    return (() => {
      afterEach()
    })
  }, [])

  return <ErrorBoundary data={data} >
    {
      React.cloneElement(props.children, { params: pageData.current.params, onChangePath: onChangePath, isDestroy: isDestroy })
    }
  </ErrorBoundary>
}


export default ComponentWrap

import React from 'react'
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { ErrorPage } from '@yisa/webui_business'

function _404(props) {

  const routes = useSelector((state) => {
    return state.user.route
  })

  const history = useHistory()

  const goHome = () => {
    history.replace('/')
  }

  return <ErrorPage showJump={routes.length} jumpPage={goHome} type='404' />
}

export default _404

import { ajax } from '@/utils';

export default {
  getMarketStatistics: (data) => (
    ajax({
      url: '/summary/statistics-by-behavior',
      method: 'post',
      params: data || {}
    })
  ),
  getGasStatistics: (data) => (
    ajax({
      url: '/summary/gas-num-by-unit',
      method: 'post',
      params: data || {}
    })
  ),
  getOverviewRealTime: (data) => (
    ajax({
      url: '/summary/event-real-time',
      method: 'post',
      params: data || {}
    })
  ),
  updateAlgs: (data) => (
    ajax({
      url: '/device/enable-algs',
      method: 'post',
      params: data || {}
    })
  )
}
